import React, { useEffect, useRef, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Swal from 'sweetalert2';
import '../../css/billstyle.css'
import {useDispatch, useSelector } from 'react-redux';
import { fatchBillAPI,fatchSignAPI,fatchUserAPI,fatchPhotoAPI,EditBillAPI,fatchTearmsAPI,fatchBankAPI} from '../../redux/actionCreation';
import { parseISO, format } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Link, useNavigate } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css'; // Import the styles
import DOMPurify from 'dompurify';
import { Helmet } from 'react-helmet';
import html2pdf from 'html2pdf.js';
import Loader from '../Loader';

function Tempone() {
    
    //view port set
    useEffect(() => {
        // Update the viewport meta tag when ABCComponent mounts
        const metaTag = document.querySelector('meta[name="viewport"]');
        if (metaTag) {
          metaTag.setAttribute('content', 'width=device-width, initial-scale=0.4, maximum-scale=1.0, user-scalable=yes');
        }
    
        // Clean up function to reset the viewport when ABCComponent unmounts
        return () => {
          if (metaTag) {
            metaTag.setAttribute('content', 'width=device-width, initial-scale=1');
          }
        };
      }, []); // Run only once on component mount
    const navigate = useNavigate();
    useEffect(()=>{
        if(!localStorage.getItem('tokan')){
            navigate('/login');
        }
    }, []);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [loading_update, setLoading_update] = useState(false);
    const dispatchFetchPhoto = useDispatch();
    const [selectedBillId, setSelectedBillId] = useState(null);
    const [filteredBill, setFilteredBill] = useState([]);
    const ref2 = useRef(null);
    const fetchBillReducers = useSelector(state => state.fetchBillReducers);
    const signatures = useSelector((state) => state.fetchReducers);
    const hasSignature = signatures.data.some(signature => signature.signature_data);
    const fetchcompanyLogo = useSelector(state => state.fetchcompanyLogo);
    const FetchBankReducers = useSelector(state => state.FetchBankReducers);
    const error_edit = useSelector(state => state.EditBillReducers.error?.edit_bill_errors) ?? null;

    const fetchUserReducres = useSelector(state => state.fetchUserReducres);
    const email = fetchUserReducres.data ? fetchUserReducres.data.email : null;
    const company_name = fetchUserReducres.data ? fetchUserReducres.data.company_name : null;
    const phone_number = fetchUserReducres.data ? fetchUserReducres.data.phone_number : null;
    const phone_number_2 = fetchUserReducres.data ? fetchUserReducres.data.phone_number_2 : null;
    const company_website = fetchUserReducres.data ? fetchUserReducres.data.company_website : null;
    const company_GST = fetchUserReducres.data ? fetchUserReducres.data.company_GST : null;
    const company_add = fetchUserReducres.data ? fetchUserReducres.data.company_add : null;
    const FeatchTearmsReducers = useSelector(state => state.FeatchTearmsReducers);

   

    const [formData, setFormData] = useState({  
        name: '',
        phone_number: '',
        bill_date: '',
        customer_address: '',
        service_name: '',
        from_city: '',
        to_city: '',
        shift_start_date: '',
        shift_end_date: '',
        shift_items: '',
        transportation_charge: '',
        packing_charge: '',
        unpacking_charge: '',
        loading_charge: '',
        unloading_charge: '',
        insurance_charge: '',
        service_charge: '',
        storage_charge: '',
        other_charge: '',
        total_charge: '',
        sgst_per: '',
        sgst_charge: '',
        cgst_per: '',
        cgst_charge: '',
        gst_per: '',
        gst_charge: '',
      });

      useEffect(() => {
        const fetchDataTearms = async () => {
            try {
                const res = await dispatch(fatchTearmsAPI());
                // Process the result if needed
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchDataTearms(); // Call the async function
    }, []);

    useEffect(() => {
        const fetchDataUser = async () => {
            try {
                const res = await dispatch(fatchUserAPI());
            } catch (error) {
                console.error('Error fetching Company Details data:', error);
            }
        };
        fetchDataUser();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const res = await dispatch(fatchBillAPI());
                setLoading(false);
                // Process the result if needed
            } catch (error) {
                console.log('Error fetching data:', error);
            }
        };
        fetchData(); // Call the async function
    }, []);

    useEffect(() => {
        const fetchDataSign = async () => {
            try {
                const res = await dispatch(fatchSignAPI());
                // Process the result if needed
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchDataSign(); // Call the async function
    }, []);

    useEffect(() => {
        const fetchPhoto = async () => {
            try {
              const res = await dispatchFetchPhoto(fatchPhotoAPI());
                // Process the result if needed
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchPhoto(); // Call the async function
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await dispatch(fatchBankAPI());
                // Process the result if needed
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData(); // Call the async function
    }, []);

      const [formdate, setFormDate] = useState(null);
      const [todate, setToDate] = useState(null);
      const fromDate = (date) => {
          setFormDate(date);
        };
        const toDate = (date) => {
          setToDate(date);
        };

         // Function to calculate total of specified fields
   const calculateTotal = () => {
    const {
      transportation_charge,
      packing_charge,
      unpacking_charge,
      loading_charge,
      unloading_charge,
      insurance_charge,
      storage_charge,
      other_charge
    } = formData;

    const total =
      parseFloat(transportation_charge) +
      parseFloat(packing_charge) +
      parseFloat(unpacking_charge) +
      parseFloat(loading_charge) +
      parseFloat(unloading_charge) +
      parseFloat(insurance_charge) +
      parseFloat(storage_charge) +
      parseFloat(other_charge);
    return total;
  };

     // Function to calculate SGST charge based on total and cgst_per
     const calculateSGSTCharge = (total, sgstPercentage) => {
        return (total * parseFloat(sgstPercentage)) / 100;
    };

    // Function to calculate CGST charge based on total and cgst_per
    const calculateCGSTCharge = (total, cgstPercentage) => {
        return (total * parseFloat(cgstPercentage)) / 100;
    };
    
    // Function to calculate IGST charge based on total and gst_per
    const calculateGSTCharge = (total, gstPercentage) => {
        return (total * parseFloat(gstPercentage)) / 100;
    };

    // useEffect to watch for changes in relevant fields and recalculate charges
    useEffect(() => {
        const total = calculateTotal();
        const gstCharge = calculateGSTCharge(total, formData.gst_per);
        const cgstCharge = calculateCGSTCharge(total, formData.cgst_per);
        const sgstCharge = calculateSGSTCharge(total, formData.sgst_per);
        // Update state with the calculated values
        setFormData((prevData) => ({
        ...prevData,
        total_charge:total.toFixed(2),
        gst_charge: gstCharge.toFixed(2), // Adjust decimal places as needed
        cgst_charge: cgstCharge.toFixed(2), // Adjust decimal places as needed
        sgst_charge: sgstCharge.toFixed(2), // Adjust decimal places as needed
        }));
    }, [
        formData.transportation_charge,
        formData.packing_charge,
        formData.unpacking_charge,
        formData.loading_charge,
        formData.unloading_charge,
        formData.insurance_charge,
        formData.storage_charge,
        formData.other_charge,
        formData.sgst_per,
        formData.cgst_per,
        formData.gst_per,
    ]);

      useEffect(() => {
        setFilteredBill(fetchBillReducers.data);
    }, [fetchBillReducers.data]); 
  

      const formatDate = (dateString) => {
        const date = new Date(dateString);
        const formattedDate = date.toISOString().split('T')[0];
        return formattedDate;
    };

    // Function to handle link click
    const UpdateBillClick = (billId) => {
        setSelectedBillId(billId);
        const selectedBill = fetchBillReducers.data.find(bill => bill.id === billId);
        if (selectedBill) {
                const formattedDate = formatDate(selectedBill.bill_date);
                const shift_start_date = formatDate(selectedBill.shift_start_date);
                const shift_end_date = formatDate(selectedBill.shift_end_date);
                const defaultTime = new Date();
                defaultTime.setHours(0, 0, 0, 0);
            setFormData({
                ...formData,
                name: selectedBill.name,
                phone_number: selectedBill.phone_number,
                bill_date: new Date(formattedDate + 'T00:00:00'),
                customer_address: selectedBill.customer_address,
                service_name: selectedBill.service_name,
                from_city: selectedBill.from_city,
                to_city: selectedBill.to_city,
                shift_start_date: new Date(shift_start_date + 'T00:00:00'),
                shift_end_date: new Date(shift_end_date + 'T00:00:00'),
                shift_items: selectedBill.shift_items,
                transportation_charge: selectedBill.transportation_charge,
                packing_charge: selectedBill.packing_charge,
                unpacking_charge: selectedBill.unpacking_charge,
                loading_charge: selectedBill.loading_charge,
                unloading_charge: selectedBill.unloading_charge,
                insurance_charge: selectedBill.insurance_charge,
                service_charge: selectedBill.service_charge,
                storage_charge: selectedBill.storage_charge,
                other_charge: selectedBill.other_charge,
                sgst_per: selectedBill.sgst_per,
                sgst_charge: selectedBill.sgst_charge,
                cgst_per: selectedBill.cgst_per,
                cgst_charge: selectedBill.cgst_charge,
                gst_per: selectedBill.gst_per,
                gst_charge: selectedBill.gst_charge,
              });
        } else {
            console.log("Bill not found");
        }
    };
    
    const UpdateBill = async (event) => {
        event.preventDefault();
        setLoading_update(true);
        try {
            const response = await dispatch(EditBillAPI(formData, selectedBillId));
            setLoading_update(false);
            if(response?.type == "ADD_DATA_SUCCESS_EDIT_BILL"){
                ref2.current.click();
                await Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'Bill updated successfully.',
                    customClass: {
                        container: 'my-custom-swal', // Add your custom class here
                    },
                });
               dispatch(fatchBillAPI());
            }
              else {
              // Show error SweetAlert
                await Swal.fire({
                    icon: 'error',
                    title: 'Some Error',
                    text: 'Failed to update the bill. Please try again.',
                });
            }
              
        } catch (error) {
          console.error('Error updating bill:', error);
        }
      };

      const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(formData => ({
          ...formData,
          [name]: value
        }));
      };

      const handleBillDateChange = (date) => {
        const modifiedDate = new Date(date);
        modifiedDate.setHours(0, 0, 0, 0);
        // Format the date to "dd-mm-yyyy"
        const day = String(modifiedDate.getDate()).padStart(2, '0');
        const month = String(modifiedDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = String(modifiedDate.getFullYear());
    
        const formattedDate = `${day}-${month}-${year}`;
        console.log("date", formattedDate);
        // Update the recipts_date field in formData when the DatePicker changes
        setFormData((prevState) => ({
            ...prevState,
            bill_date: modifiedDate,
        }));
    };
    
      const handleStartDateChange = (date) => {
        const modifiedDate = new Date(date);
        modifiedDate.setHours(0, 0, 0, 0);
        // Format the date to "dd-mm-yyyy"
        const day = String(modifiedDate.getDate()).padStart(2, '0');
        const month = String(modifiedDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = String(modifiedDate.getFullYear());
    
        const formattedDate = `${day}-${month}-${year}`;
        console.log("date", formattedDate);
        // Update the recipts_date field in formData when the DatePicker changes
        setFormData((prevState) => ({
            ...prevState,
            shift_start_date: modifiedDate,
        }));
      };
    
      const handleEndDateChange = (date) => {
        const modifiedDate = new Date(date);
        modifiedDate.setHours(0, 0, 0, 0);
        // Format the date to "dd-mm-yyyy"
        const day = String(modifiedDate.getDate()).padStart(2, '0');
        const month = String(modifiedDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = String(modifiedDate.getFullYear());
    
        const formattedDate = `${day}-${month}-${year}`;
        console.log("date", formattedDate);
        // Update the recipts_date field in formData when the DatePicker changes
        setFormData((prevState) => ({
            ...prevState,
            shift_end_date: modifiedDate,
        }));
      };

      const imageUrl = `https://app.packersbilling.in/api/${fetchcompanyLogo.data.path}`;
      const [base64URL, setBase64URL] = useState('');
      useEffect(() => {
          const fetchImage2 = async () => {
          try {
              const response = await fetch(imageUrl);
              if (!response.ok) {
              throw new Error(`Failed to fetch image. Status: ${response.status}`);
              }
              const blob = await response.blob();
              const reader = new FileReader();
              reader.onloadend = () => {
              const base64 = reader.result;
              setBase64URL(base64);
              };
              reader.readAsDataURL(blob);
          } catch (error) {
              console.error('Error fetching or converting the image:', error);
          }
          };
    
          fetchImage2();
      }, [imageUrl]);
    
      const handleDownloadPdf = () => {
          const element = document.getElementById('content-to-pdf');
          html2pdf(element, {
            margin: 4,
            filename: 'invoice.pdf',
            html2canvas: {
              scale: 2,
              useCORS: true, // Add this option to handle cross-origin requests
            },
            jsPDF: {
              unit: 'mm',
              format: 'a4',
              orientation: 'portrait',
            },
          });
        };

  return (
    <>
    {loading && <div className='text-center mt-4'>
        <Loader />
    </div>}
    {!loading && (
        <Row>
            {fetchBillReducers.data && Array.isArray(fetchBillReducers.data) && fetchBillReducers.data.length > 0 ? (
        [...fetchBillReducers.data].reverse().slice(0,1).map(item => (
        <div key={item.id} className="container-fluid invoice-container">
            <div className="table-responsive" id='content-to-pdf'>
                <table className="table table-bordered border mb-0">
                    <tbody>
                        <tr>
                        <td colspan="2" className="bg-light text-center">
                            <h3 className="mb-0">
                                {company_name}
                            </h3></td>
                        </tr>
                        <tr>
                            <td colspan="2" className="text-center text-lowercase">
                                <span><i class="fa fa-map-marker-alt"></i> {company_add}</span><br/>
                                <span className='email'><i class="fa fa-envelope"></i> {email}
                                {company_website && ( // Check if company_GST exists
                                    <span className='text-lowercase'> <i class="fa fa-globe-americas"></i> {company_website}</span>
                                )}
                                </span>
                            </td>
                        </tr>
                        <tr>
                        <td colspan="2" className="py-1 bg-light v-center">
                            <div className="row">
                                {company_GST && ( // Check if company_GST exists
                                    <div className="col">
                                        <strong><i className="fa fa-file"></i> GSTIN No : {company_GST}</strong>
                                    </div>
                                )}
                                {/* <div className="col"><strong><i class="fa fa-file"></i> GSTIN No.: {company_GST}</strong></div> */}
                                <div className="col text-center fw-600 text-3 text-uppercase">Tax Invoice</div>
                                <div className="col text-end">
                                    <strong><i class="fa fa-phone"></i>
                                        <span> {phone_number} </span>
                                        {phone_number_2 && ( // Check if company_GST exists
                                            <span>, {phone_number_2}</span>
                                        )}
                                        
                                    </strong>
                                </div>
                            </div>
                        </td>
                        </tr>
                        <tr>
                        <td className="col-8">
                            <div className="row gx-2 gy-2">
                                <div className="col-6"><strong>Customer Name : </strong> 
                                <p>{item.name}</p>
                                </div>

                                <div className="col-3"><strong>From : </strong> 
                                <p>{item.from_city}</p>
                                </div>

                                <div className="col-3"><strong>To : </strong> 
                                <p>{item.to_city}</p>
                                </div>
                                <div className="col-12 mt-0"><strong>Customer Address  : </strong> {item.customer_address}</div>

                                <div className="col-12 mt-2"><strong>Shifting Items : </strong> 
                                    {item.shift_items}
                                </div>
                            </div>
                        </td>
                        <td className="col-4 bg-light">
                            <div className="row gx-2 gy-1 fw-600">
                                <div className="col-6">Invoice No <span className="float-end">:</span></div>
                                <div className="col-6">{item.invoice_number}</div>
                                                        
                                <div className="col-6">Invoice Date <span className="float-end">:</span></div>
                                <div className="col-6">
                                    {(() => {
                                        try {
                                        return format(parseISO(item.bill_date), 'dd-MM-yyyy');
                                        } catch (error) {
                                        console.error('Error parsing date:', item.bill_date);
                                        return 'Invalid Date';
                                        }
                                    })()}
                                </div>

                                <div className="col-6">Shift Start Date <span className="float-end">:</span></div>
                                <div className="col-6">
                                    {(() => {
                                        try {
                                        return format(parseISO(item.shift_start_date), 'dd-MM-yyyy');
                                        } catch (error) {
                                        console.error('Error parsing date:', item.shift_start_date);
                                        return 'Invalid Date';
                                        }
                                    })()}
                                </div>

                                <div className="col-6">Shift End Date <span className="float-end">:</span></div>
                                <div className="col-6">
                                    {(() => {
                                        try {
                                        return format(parseISO(item.shift_end_date), 'dd-MM-yyyy');
                                        } catch (error) {
                                        console.error('Error parsing date:', item.shift_end_date);
                                        return 'Invalid Date';
                                        }
                                    })()}
                                </div>

                                <div className="col-4">Service <span className="float-end">:</span></div>
                                <div className="col-8">{item.service_name}</div>

                                <div className="col-4">Contact <span className="float-end">:</span></div>
                                <div className="col-8">{item.phone_number}</div>
                                
                            </div>
                        </td>
                        </tr>
                        <tr>
                            <td colspan="2" className="p-0">
                                <table class="table text-1 mb-0">
                                    <tbody>
                                        <tr class="bg-light">
                                            <td colspan="6" class="pt-2 pb-2 pr-1 pl-2 text-center"><strong>Shifting Charges</strong></td>
                                        </tr>
                                        <tr>
                                            <td><strong>Transportation Charge</strong></td>
                                            <td>{item.transportation_charge}</td>
                                            <td><strong>Packing Charge</strong></td>
                                            <td>{item.packing_charge}</td>
                                            <td><strong>Unpacking Charge</strong></td>
                                            <td>{item.unpacking_charge}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Loading Charge</strong></td>
                                            <td>{item.loading_charge}</td>
                                            <td><strong>Unloading Charge</strong></td>
                                            <td>{item.unloading_charge}</td>
                                            <td><strong>Insurance Charge</strong></td>
                                            <td>{item.insurance_charge}</td>
                                        </tr>
                                        <tr className='b-none'>
                                            {/* <td><strong>Service Charge</strong></td>
                                            <td>{item.service_charge}</td> */}
                                            <td><strong>Storage Charge</strong></td>
                                            <td>{item.storage_charge}</td>
                                            <td><strong>Other Charge</strong></td>
                                            <td>{item.other_charge}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr className="bg-light fw-600 border-style">
                            <td className="col-7 text-1" rowSpan={4}><span className="fw-600"></span>
                            <h4 className="text-3 mt-1 mb-1">Bank Details:</h4>
                            {FetchBankReducers && Array.isArray(FetchBankReducers.data) && FetchBankReducers.data.length > 0 ? (
                                [...FetchBankReducers.data].reverse().map(item => (
                                <div key={item.id} class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-0">
                                    <div class="d-flex justify-content-between align-items-center mt-2">
                                        <p class="text-dark profile_name">Account Holder Name :</p> <h6 class="text-dark profile_name_data mx-2">{item.account_holder_name}</h6>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <p class="text-dark profile_name">Bank Name :</p>
                                        <h6 class="text-dark profile_name_data mx-2">{item.bank_name}</h6>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <p class="text-dark profile_name">Account Number:</p>
                                        <h6 class="text-dark profile_name_data mx-2">{item.account_number}</h6>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <p class="text-dark profile_name">IFFC Code :</p>
                                        <h6 class="text-dark profile_name_data mx-2">{item.iffc_code}</h6>
                                    </div>
                                </div>
                                ))
                                ) : (
                                    <div className='text-left not_data mt-3 mb-3'>
                                        <Link to="/update-company" className='btn btn-primary'>Add Bank Details</Link>
                                    </div>
                                )} 

                                <h4 className="text-3 mt-0">Terms and conditions:</h4>
                                {FeatchTearmsReducers && Array.isArray(FeatchTearmsReducers.data) && FeatchTearmsReducers.data.length > 0 ? (
                                [...FeatchTearmsReducers.data].reverse().map(item => (
                                <h6 className="text-dark profile_name_data_terms printpage mb-0" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.tearms) }} />
                            ))
                            ) : (
                                <div className='text-center not_data mt-3'>
                                <Link to="/update-company" className='d-print-none btn btn-primary'>Add Tearms+</Link>
                                </div>
                                )} 
                            </td>
                            <td className="col-5 py-1 pe-1 v-center">Sub Total: <span className="float-end"><i className='fa fa-indian-rupee'></i> {item.total}</span></td>
                        </tr>
                        <tr className='border-style'>
                            <td className="col-5 pe-1">
                                <strong>SGST Tax :</strong> <small>({item.sgst_per}%)</small> <span className="float-end"><i className='fa fa-indian-rupee'></i> {item.sgst_charge}</span> <br />

                                <strong>CGST Tax :</strong> <small>({item.cgst_per}%)</small> <span className="float-end"><i className='fa fa-indian-rupee'></i> {item.cgst_charge}</span><br />

                                <strong>IGST :</strong> <small>({item.gst_per}%)</small> <span className="float-end"><i className='fa fa-indian-rupee'></i> {item.gst_charge}</span><br/>

                                <strong>Service Tax :</strong> <span className="float-end"><i className='fa fa-indian-rupee'></i> {item.service_charge}</span><br/>
                                
                            </td>
                        </tr>
                        <tr className='border-style'>
                            <td className="col-5 pe-1 bg-light fw-600">
                            Grand Total:<span className="float-end"><i className='fa fa-indian-rupee'></i> {item.total_amount}</span>
                            </td>
                        </tr>
                        <tr className='border-style'>
                            <td className="col-5 pe-1 text-end">
                                <span className='text-blue stemp d-block text-end'>For, {company_name}</span>
                                {[...signatures.data].map((signature, index) => (
                                    <img key={index} src={signature.signature_data} alt={`Signature ${index + 1}`} className='sign-size' />
                                ))}
                                {!hasSignature && (
                                    <Link to="/update-company" className='d-print-none btn btn-primary'>
                                        Add Sign
                                    </Link>
                                )}
                                <div className="text-1 fst-italic mt-0">
                                    <span className='text-blue stemp'>(Authorised Signatory)</span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <span className='mb-0 mt-2 d-block text-center'><strong>NOTE :</strong> This is computer generated invoice and does not require physical signature.</span>
            </div>
            <footer className="text-center mt-4 invoice_design">
            <button
                    onClick={() => item && UpdateBillClick(item.id)}
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#staticBackdrop"
                    aria-controls="staticBackdrop" className='btn btn-info bill_btn pt-2 pb-2 d-print-none'>
                    Edit
                </button>
                

                <div className="btn-group btn-group-sm d-print-none">
                    <a href="javascript:window.print()" className="btn btn-info quot_btn pt-2 pb-2"><i className="fa fa-print"></i> Print & Download</a> 
                </div>

                <button
                onClick={handleDownloadPdf}
                type="button"
                className='btn btn-info bill_btn pt-2 pb-2 d-print-none'>
                    Download as PDF
                </button>

                <Link class="btn btn-info plus_btn text-white pt-2 pb-2 d-print-none" to="/list-bills">Done</Link>
            </footer>
        </div>
            ))
            ): (
                <div className='text-center not_data mt-3'>
                    <i class="fa-solid fa-triangle-exclamation"></i>
                    <p>No data available</p>
                </div>
            )}
        </Row>
      )}
            {/* Edit Invoice Sidebar Start*/}
            <div class="offcanvas offcanvas-start" data-bs-backdrop="static" tabIndex="-1" id="staticBackdrop" aria-labelledby="staticBackdropLabel" style={{ zIndex: 99999999999 }}>
                <div class="offcanvas-header bg-info">
                    <h5 class="offcanvas-title text-white" id="staticBackdropLabel">Edit Invoice</h5>
                    <button type="button" ref={ref2} class="btn-close btn-close2" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">
                    <div>
                        <Form onSubmit={UpdateBill}>
                            <Row className="mb-3">
                            <h6 className='mb-0'>General Details:</h6>
                                <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12">
                                    <hr className='w-10'/>
                                </Col>

                                <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="formGridEmail" className='mt-2'>
                                    <Form.Label>Customer Name</Form.Label>
                                        <Form.Control type="text" onChange={handleChange} placeholder="Customer Name.." name="name" value={formData.name} required/>
                                        {error_edit?.name && (
                                        <span className="alert alert-danger d-block mb-1 mt-1 pt-1 pb-1">
                                            {error_edit.name && error_edit.name}
                                        </span>
                                        )}
                                </Form.Group>

                                <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="formGridPassword" className='mt-2'>
                                        <Form.Label>Phone</Form.Label>
                                            <Form.Control type="number" placeholder="Customer Phone.." onChange={handleChange} name="phone_number" value={formData.phone_number} required/>

                                            {error_edit?.phone_greater && (
                                            <span className="alert alert-danger d-block mt-1 mb-1 pt-1 pb-1">
                                            {error_edit.phone_greater && error_edit.phone_greater}
                                            </span>
                                            )}

                                            {error_edit?.phone_less && (
                                                <span className="alert alert-danger d-block mt-1 mb-1 pt-1 pb-1">
                                                {error_edit.phone_less && error_edit.phone_less}
                                                </span>
                                            )}
                                </Form.Group>

                                <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="formGridPassword" className='mt-2'>
                                    <Form.Label>Bill Date</Form.Label>
                                            <div className="date-picker-container">
                                                <i className='calendar-icon fa fa-calendar'></i>
                                                <DatePicker
                                                    selected={formData.bill_date}
                                                    onChange={handleBillDateChange}
                                                    dateFormat="dd-MM-yyyy"
                                                    placeholderText="Bill Date"
                                                    className='form-control'
                                                    required
                                                />
                                            </div>
                                </Form.Group>

                                <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="exampleForm.ControlTextarea1" className='mt-2'>
                                    <Form.Label>Customer Address</Form.Label>
                                    <Form.Control as="textarea" rows={3} placeholder="Customer Address.." onChange={handleChange} name="customer_address" value={formData.customer_address} required/>
                                    </Form.Group>

                                    <h6 className='mb-0 mt-3'>Shifting Details:</h6>
                                        <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12">
                                            <hr className='w-10'/>
                                        </Col>

                                    <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="formGridEmail" className='mt-2'>
                                        <Form.Label>Service Name</Form.Label>
                                        <Form.Control type="text" placeholder="Service Name.." onChange={handleChange} name="service_name" value={formData.service_name} required/>
                                    </Form.Group>

                                    <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridEmail" className='mt-2'>
                                        <Form.Label>From City/Area</Form.Label>
                                        <Form.Control type="text" placeholder="From.." onChange={handleChange} name="from_city" value={formData.from_city} required/>
                                    </Form.Group>

                                    <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridEmail" className='mt-2'>
                                        <Form.Label>To City/Area</Form.Label>
                                        <Form.Control type="text" placeholder="To.." onChange={handleChange} name="to_city" value={formData.to_city} required/>
                                    </Form.Group>

                                    <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                        <Form.Label>Shifting Start Date</Form.Label>
                                            <div className="date-picker-container">
                                                <i className='calendar-icon fa fa-calendar'></i>
                                                <DatePicker
                                                    selected={formData.shift_start_date}
                                                    onChange={handleStartDateChange}
                                                    dateFormat="dd-MM-yyyy"
                                                    placeholderText="Start Date"
                                                    className='form-control'
                                                    required
                                                />
                                            </div>
                                        </Form.Group>

                                        <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                            <Form.Label>Shifting End Date</Form.Label>
                                            <div className="date-picker-container">
                                                <i className='calendar-icon fa fa-calendar'></i>
                                                <DatePicker
                                                    selected={formData.shift_end_date}
                                                    onChange={handleEndDateChange}
                                                    dateFormat="dd-MM-yyyy"
                                                    placeholderText="End Date"
                                                    className='form-control'
                                                    required
                                                />
                                            </div>
                                            {error_edit?.shift_end_date_final && (
                                            <span className="alert alert-danger d-block mt-1 mb-1 pt-1 pb-1">
                                                {error_edit.shift_end_date_final && error_edit.shift_end_date_final}
                                            </span>
                                            )}
                                        </Form.Group>

                                            <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="exampleForm.ControlTextarea1" className='mt-2'>
                                                <Form.Label>Shifting Items</Form.Label>
                                                <Form.Control as="textarea" rows={3} placeholder="Shifting Items.." onChange={handleChange} name="shift_items" value={formData.shift_items} required/>
                                            </Form.Group>

                                            <h6 className='mb-0 mt-3'>Shifting Charges :</h6>
                                            <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12">
                                                <hr className='w-10'/>
                                            </Col>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword"  className='mt-2'>
                                                <Form.Label>Transportation Charge</Form.Label>
                                                <Form.Control type="number" placeholder="Transportation Charge.." onChange={handleChange} name="transportation_charge" value={formData.transportation_charge} required/>
                                            </Form.Group>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword"  className='mt-2'>
                                                <Form.Label>Packing Charge</Form.Label>
                                                <Form.Control type="number" placeholder="Packing Charge.." onChange={handleChange} name="packing_charge" value={formData.packing_charge} required/>
                                            </Form.Group>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword"  className='mt-2'>
                                                <Form.Label>Unpacking Charge</Form.Label>
                                                <Form.Control type="number" placeholder="Unpacking Charge.." onChange={handleChange} name="unpacking_charge" value={formData.unpacking_charge} required/>
                                            </Form.Group>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                                <Form.Label>Loading Charge</Form.Label>
                                                <Form.Control type="number" onChange={handleChange} placeholder="Loading Charge.." name="loading_charge" value={formData.loading_charge} required/>
                                            </Form.Group>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                                <Form.Label>Unloading Charge</Form.Label>
                                                <Form.Control type="number" placeholder="Unloading Charge.." onChange={handleChange} name="unloading_charge" value={formData.unloading_charge} required/>
                                            </Form.Group>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                                <Form.Label>Insurance Charge</Form.Label>
                                                <Form.Control type="number" placeholder="Insurance Charge.." onChange={handleChange} name="insurance_charge" value={formData.insurance_charge} required/>
                                            </Form.Group>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword"  className='mt-2'>
                                                <Form.Label>Storage Charge</Form.Label>
                                                <Form.Control type="number" placeholder="Storage Charge.." onChange={handleChange} name="storage_charge" value={formData.storage_charge} required/>
                                            </Form.Group>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                                <Form.Label>Other Charge</Form.Label>
                                                <Form.Control type="number" placeholder="Other Charge.." onChange={handleChange} name="other_charge" value={formData.other_charge} required/>
                                            </Form.Group>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                                <Form.Label>Total</Form.Label>
                                                <Form.Control type="number" placeholder="Total.." name="total_charge" value={calculateTotal()} readOnly/>
                                            </Form.Group>

                                            <h6 className='mb-0 mt-3'>Tax Charges :</h6>
                                            <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12">
                                                <hr className='w-10'/>
                                            </Col>

                                            <div className='form-group mt-2 col-md-6 col-lg-6 col-sm-12 col-xs-12'>
                                            <Form.Label for="exampleSelect2">Select SGST %</Form.Label>
                                                <select className='form-select' id="exampleSelect2" onChange={handleChange} name="sgst_per" value={formData.sgst_per} required>
                                                    <option value="">Select % </option>
                                                    <option value="0">0%</option>
                                                    <option value="1">1%</option>
                                                    <option value="2">2%</option>
                                                    <option value="2.5">2.5%</option>
                                                    <option value="3">3%</option>
                                                    <option value="3.5">3.5%</option>
                                                    <option value="4">4%</option>
                                                    <option value="4.5">4.5%</option>
                                                    <option value="5">5%</option>
                                                    <option value="5.5">5.5%</option>
                                                    <option value="6">6%</option>
                                                    <option value="6.5">6.5%</option>
                                                    <option value="7">7%</option>
                                                    <option value="7.5">7.5%</option>
                                                    <option value="8">8%</option>
                                                    <option value="8.5">8.5%</option>
                                                    <option value="9">9%</option>
                                                    <option value="9.5">9.5%</option>
                                                    <option value="10">10%</option>
                                                    <option value="10.5">10.5%</option>
                                                    <option value="11">11%</option>
                                                    <option value="11.5">11.5%</option>
                                                    <option value="12">12%</option>
                                                    <option value="12.5">12.5%</option>
                                                    <option value="13">13%</option>
                                                    <option value="13.5">13.5%</option>
                                                    <option value="14">14%</option>
                                                    <option value="14.5">14.5%</option>
                                                </select>
                                                {error_edit?.sgst_per && (
                                                <span className="alert alert-danger d-block mt-1 mb-1 pt-1 pb-1">
                                                    {error_edit.sgst_per && error_edit.sgst_per}
                                                </span>
                                            )}
                                            </div>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                                <Form.Label>SGST Charge</Form.Label>
                                                <Form.Control type="number" placeholder="SGST Charge.." name="sgst_charge" value={formData.sgst_charge} readOnly/>
                                            </Form.Group>

                                            <div className='form-group mt-2 col-md-6 col-lg-6 col-sm-12 col-xs-12'>
                                            <Form.Label for="exampleSelect2">Select CGST %</Form.Label>
                                                <select className='form-select' id="exampleSelect2" onChange={handleChange} name="cgst_per" value={formData.cgst_per} required>
                                                    <option value="">Select % </option>
                                                    <option value="0">0%</option>
                                                    <option value="1">1%</option>
                                                    <option value="2">2%</option>
                                                    <option value="2.5">2.5%</option>
                                                    <option value="3">3%</option>
                                                    <option value="3.5">3.5%</option>
                                                    <option value="4">4%</option>
                                                    <option value="4.5">4.5%</option>
                                                    <option value="5">5%</option>
                                                    <option value="5.5">5.5%</option>
                                                    <option value="6">6%</option>
                                                    <option value="6.5">6.5%</option>
                                                    <option value="7">7%</option>
                                                    <option value="7.5">7.5%</option>
                                                    <option value="8">8%</option>
                                                    <option value="8.5">8.5%</option>
                                                    <option value="9">9%</option>
                                                    <option value="9.5">9.5%</option>
                                                    <option value="10">10%</option>
                                                    <option value="10.5">10.5%</option>
                                                    <option value="11">11%</option>
                                                    <option value="11.5">11.5%</option>
                                                    <option value="12">12%</option>
                                                    <option value="12.5">12.5%</option>
                                                    <option value="13">13%</option>
                                                    <option value="13.5">13.5%</option>
                                                    <option value="14">14%</option>
                                                    <option value="14.5">14.5%</option>
                                                </select>
                                                {error_edit?.cgst_per && (
                                                <span className="alert alert-danger d-block mt-1 mb-1 pt-1 pb-1">
                                                    {error_edit.cgst_per && error_edit.cgst_per}
                                                </span>
                                            )}
                                            </div>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                                <Form.Label>CGST Charge</Form.Label>
                                                <Form.Control type="number" placeholder="CGST Charge.." name="cgst_charge" value={formData.cgst_charge} readOnly/>
                                            </Form.Group>
                                            

                                            <div className='form-group mt-2 col-md-6 col-lg-6 col-sm-12 col-xs-12'>
                                            <Form.Label for="exampleSelect">Select IGST %</Form.Label>
                                            <select className='form-select' id="exampleSelect" onChange={handleChange} name="gst_per" value={formData.gst_per} required>
                                                <option value="">Select % </option>
                                                <option value="0">0%</option>
                                                <option value="1">1%</option>
                                                <option value="2">2%</option>
                                                <option value="2.5">2.5%</option>
                                                <option value="3">3%</option>
                                                <option value="3.5">3.5%</option>
                                                <option value="4">4%</option>
                                                <option value="4.5">4.5%</option>
                                                <option value="5">5%</option>
                                                <option value="5.5">5.5%</option>
                                                <option value="6">6%</option>
                                                <option value="6.5">6.5%</option>
                                                <option value="7">7%</option>
                                                <option value="7.5">7.5%</option>
                                                <option value="8">8%</option>
                                                <option value="8.5">8.5%</option>
                                                <option value="9">9%</option>
                                                <option value="9.5">9.5%</option>
                                                <option value="10">10%</option>
                                                <option value="10.5">10.5%</option>
                                                <option value="11">11%</option>
                                                <option value="11.5">11.5%</option>
                                                <option value="12">12%</option>
                                                <option value="12.5">12.5%</option>
                                                <option value="13">13%</option>
                                                <option value="13.5">13.5%</option>
                                                <option value="14">14%</option>
                                                <option value="14.5">14.5%</option>
                                                <option value="15">15%</option>
                                                <option value="16">16%</option>
                                                <option value="17">17%</option>
                                                <option value="18">18%</option>
                                                <option value="19">19%</option>
                                                <option value="20">20%</option>
                                                <option value="21">21%</option>
                                                <option value="22">22%</option>
                                                <option value="23">23%</option>
                                                <option value="24">24%</option>
                                                <option value="25">25%</option>
                                                <option value="26">26%</option>
                                                <option value="27">27%</option>
                                                <option value="28">28%</option>
                                            </select>
                                            {error_edit?.gst_per && (
                                            <span className="alert alert-danger d-block mb-1 mt-1 pt-1 pb-1">
                                                {error_edit.gst_per && error_edit.gst_per}
                                            </span>
                                            )}
                                        </div>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                                <Form.Label>IGST Charge</Form.Label>
                                                <Form.Control type="number" placeholder="GST Charge.." name="gst_charge" value={formData.gst_charge} readOnly/>
                                            </Form.Group>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                                <Form.Label>Service Charge</Form.Label>
                                                <Form.Control type="number" placeholder="Service Charge.." onChange={handleChange} name="service_charge" value={formData.service_charge} required/>
                                            </Form.Group>
                                        </Row>

                                        <Button variant="primary" type="submit" className='d-flex align-items-center justify-content-center'>
                                        <span className='mx-2'>Update</span>
                                        {loading_update && <span className='spin-sm'><Loader /></span>}
                                        </Button>
                        </Form>
                    </div>
                </div>
            </div>
            {/* Edit Invoice Sidebar End */}
    </>
  )
}

export default Tempone
