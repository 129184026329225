import React, { useEffect, useRef, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Swal from 'sweetalert2';
import '../../css/billstyle.css'
import {useDispatch, useSelector } from 'react-redux';
import { fatchQuotationAPI,fatchSignAPI,fatchUserAPI,fatchPhotoAPI,EditQuotationAPI,fatchTearmsAPI,fatchBankAPI} from '../../redux/actionCreation';
import { parseISO, format } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Link, useNavigate } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css'; // Import the styles
import DOMPurify from 'dompurify';
import { Helmet } from 'react-helmet';
import html2pdf from 'html2pdf.js';
import Loader from '../Loader';

function Quotedesign() {
    //view port set
    useEffect(() => {
        // Update the viewport meta tag when ABCComponent mounts
        const metaTag = document.querySelector('meta[name="viewport"]');
        if (metaTag) {
          metaTag.setAttribute('content', 'width=device-width, initial-scale=0.4, maximum-scale=1.0, user-scalable=yes');
        }
    
        // Clean up function to reset the viewport when ABCComponent unmounts
        return () => {
          if (metaTag) {
            metaTag.setAttribute('content', 'width=device-width, initial-scale=1');
          }
        };
      }, []); // Run only once on component mount
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [loading_update, setLoading_update] = useState(false);
    const dispatchFetchPhoto = useDispatch();
    const [selectedQuotId, setSelectedQuotId] = useState(null);
    const [filteredQuote, setFilteredQuote] = useState([]);
    const ref2 = useRef(null);
    const fetchQutReducers = useSelector(state => state.fetchQutReducers);
    const FeatchTearmsReducers = useSelector(state => state.FeatchTearmsReducers);
    const FetchBankReducers = useSelector(state => state.FetchBankReducers);
    const signatures = useSelector((state) => state.fetchReducers);
    const hasSignature = signatures.data.some(signature => signature.signature_data);
    const fetchcompanyLogo = useSelector(state => state.fetchcompanyLogo);
    const error_edit = useSelector(state => state.EditQuotationReducers.error?.edit_quotation_errors) ?? null;

    const fetchUserReducres = useSelector(state => state.fetchUserReducres);
    const email = fetchUserReducres.data ? fetchUserReducres.data.email : null;
    const company_name = fetchUserReducres.data ? fetchUserReducres.data.company_name : null;
    const phone_number = fetchUserReducres.data ? fetchUserReducres.data.phone_number : null;
    const phone_number_2 = fetchUserReducres.data ? fetchUserReducres.data.phone_number_2 : null;
    const company_website = fetchUserReducres.data ? fetchUserReducres.data.company_website : null;
    const company_GST = fetchUserReducres.data ? fetchUserReducres.data.company_GST : null;
    const company_add = fetchUserReducres.data ? fetchUserReducres.data.company_add : null;
    const navigate = useNavigate();
    useEffect(()=>{
        if(!localStorage.getItem('tokan')){
            navigate('/login');
        }
    }, []);

    useEffect(() => {
        const fetchDataUser = async () => {
            try {
                const res = await dispatch(fatchUserAPI());
            } catch (error) {
                console.error('Error fetching Company Details data:', error);
            }
        };
        fetchDataUser();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const res = await dispatch(fatchQuotationAPI());
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.log('Error fetching data:', error);
            }
        };
        fetchData(); // Call the async function
    }, []);

    useEffect(() => {
        const fetchDataSign = async () => {
            try {
                const res = await dispatch(fatchSignAPI());
                // Process the result if needed
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchDataSign(); // Call the async function
    }, []);

    useEffect(() => {
        const fetchPhoto = async () => {
            try {
              const res = await dispatchFetchPhoto(fatchPhotoAPI());
                // Process the result if needed
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchPhoto(); // Call the async function
    }, []);
    useEffect(() => {
        const fetchBank = async () => {
            try {
                const res = await dispatch(fatchBankAPI());
                // Process the result if needed
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchBank(); // Call the async function
    }, []);

    useEffect(() => {
        const fetchDataTearms = async () => {
            try {
                const res = await dispatch(fatchTearmsAPI());
                // Process the result if needed
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchDataTearms(); // Call the async function
    }, []);

    // Update Quotation Function
    const [formData, setFormData] = useState({  
        name: '',
        phone_number: '',
        quotation_date: '',
        customer_address: '',
        service_name: '',
        from_city: '',
        to_city: '',
        shift_start_date: '',
        shift_end_date: '',
        shift_items: '',
        transportation_charge: '',
        packing_charge: '',
        unpacking_charge: '',
        loading_charge: '',
        unloading_charge: '',
        insurance_charge: '',
        service_charge: '',
        storage_charge: '',
        other_charge: '',
        total_charge: '',
        gst_per: '',
        gst_charge: '',
        cgst_per: '',
        cgst_charge: '',
      });

         // Function to calculate total of specified fields
   const calculateTotal = () => {
    const {
        transportation_charge,
        packing_charge,
        unpacking_charge,
        loading_charge,
        unloading_charge,
        insurance_charge,
        storage_charge,
        other_charge
    } = formData;

    const total =
      parseFloat(transportation_charge) +
      parseFloat(packing_charge) +
      parseFloat(unpacking_charge) +
      parseFloat(loading_charge) +
      parseFloat(unloading_charge) +
      parseFloat(insurance_charge) +
      parseFloat(storage_charge) +
      parseFloat(other_charge);
    return total;
  };

     // Function to calculate SGST charge based on total and cgst_per
     const calculateSGSTCharge = (total, sgstPercentage) => {
        return (total * parseFloat(sgstPercentage)) / 100;
    };

    // Function to calculate CGST charge based on total and cgst_per
    const calculateCGSTCharge = (total, cgstPercentage) => {
        return (total * parseFloat(cgstPercentage)) / 100;
    };
    
    // Function to calculate IGST charge based on total and gst_per
    const calculateGSTCharge = (total, gstPercentage) => {
        return (total * parseFloat(gstPercentage)) / 100;
    };
  
    // useEffect to watch for changes in relevant fields and recalculate charges
    useEffect(() => {
        const total = calculateTotal();
        const gstCharge = calculateGSTCharge(total, formData.gst_per);
        const cgstCharge = calculateCGSTCharge(total, formData.cgst_per);
        const sgstCharge = calculateSGSTCharge(total, formData.sgst_per);
        // Update state with the calculated values
        setFormData((prevData) => ({
        ...prevData,
        total_charge:total.toFixed(2),
        gst_charge: gstCharge.toFixed(2), // Adjust decimal places as needed
        cgst_charge: cgstCharge.toFixed(2), // Adjust decimal places as needed
        sgst_charge: sgstCharge.toFixed(2), // Adjust decimal places as needed
        }));
    }, [
        formData.transportation_charge,
        formData.packing_charge,
        formData.unpacking_charge,
        formData.loading_charge,
        formData.unloading_charge,
        formData.insurance_charge,
        formData.storage_charge,
        formData.other_charge,
        formData.sgst_per,
        formData.cgst_per,
        formData.gst_per,
    ]);
  
    useEffect(() => {
        setFilteredQuote(fetchQutReducers.data);
  }, [fetchQutReducers.data]); 

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const formattedDate = date.toISOString().split('T')[0];
        return formattedDate;
    };

    
       // Function to handle link click
    const UpdateQuotationClick = (QuoteId) => {
        setSelectedQuotId(QuoteId);
        const selectedQuote = fetchQutReducers.data.find(quotation => quotation.id === QuoteId);
        if (selectedQuote) {
                const formattedDate = formatDate(selectedQuote.quotation_date);
                const shift_start_date = formatDate(selectedQuote.shift_start_date);
                const shift_end_date = formatDate(selectedQuote.shift_end_date);
                const defaultTime = new Date();
                defaultTime.setHours(0, 0, 0, 0);
            setFormData({
                ...formData,
                name: selectedQuote.name,
                phone_number: selectedQuote.phone_number,
                quotation_date: new Date(formattedDate + 'T00:00:00'),
                customer_address: selectedQuote.customer_address,
                service_name: selectedQuote.service_name,
                from_city: selectedQuote.from_city,
                to_city: selectedQuote.to_city,
                shift_start_date: new Date(shift_start_date + 'T00:00:00'),
                shift_end_date: new Date(shift_end_date + 'T00:00:00'),
                shift_items: selectedQuote.shift_items,
                transportation_charge: selectedQuote.transportation_charge,
                packing_charge: selectedQuote.packing_charge,
                unpacking_charge: selectedQuote.unpacking_charge,
                loading_charge: selectedQuote.loading_charge,
                unloading_charge: selectedQuote.unloading_charge,
                insurance_charge: selectedQuote.insurance_charge,
                service_charge: selectedQuote.service_charge,
                storage_charge: selectedQuote.storage_charge,
                other_charge: selectedQuote.other_charge,
                sgst_per: selectedQuote.sgst_per,
                sgst_charge: selectedQuote.sgst_charge,
                cgst_per: selectedQuote.cgst_per,
                cgst_charge: selectedQuote.cgst_charge,
                gst_per: selectedQuote.gst_per,
                gst_charge: selectedQuote.gst_charge,
              });
        } else {
            console.log("Quotation not found");
        }
    };
    
    const UpdateQuotation = async (event) => {
        event.preventDefault();
        setLoading_update(true);
        try {
            const response = await dispatch(EditQuotationAPI(formData, selectedQuotId));
            setLoading_update(false);
            if(response?.type == "ADD_DATA_SUCCESS_EDIT_QUOTATION"){
                ref2.current.click();
                await Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'Quotation updated successfully.',
                    customClass: {
                        container: 'my-custom-swal', // Add your custom class here
                    },
                });
               await dispatch(fatchQuotationAPI());
            }
              else {
              // Show error SweetAlert
                await Swal.fire({
                    icon: 'error',
                    title: 'Some Error',
                    text: 'Failed to update the Quotation. Please try again.',
                });
            }
              
        } catch (error) {
          console.error('Error updating Quotation:', error);
        }
      };

      const handleQuoteDateChange = (date) => {
        const modifiedDate = new Date(date);
        modifiedDate.setHours(0, 0, 0, 0);
        // Format the date to "dd-mm-yyyy"
        const day = String(modifiedDate.getDate()).padStart(2, '0');
        const month = String(modifiedDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = String(modifiedDate.getFullYear());
    
        const formattedDate = `${day}-${month}-${year}`;
        console.log("date", formattedDate);
        // Update the recipts_date field in formData when the DatePicker changes
        setFormData((prevState) => ({
            ...prevState,
            quotation_date: modifiedDate,
        }));
        };
    
      const handleStartDateChange = (date) => {
        const modifiedDate = new Date(date);
        modifiedDate.setHours(0, 0, 0, 0);
        // Format the date to "dd-mm-yyyy"
        const day = String(modifiedDate.getDate()).padStart(2, '0');
        const month = String(modifiedDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = String(modifiedDate.getFullYear());
    
        const formattedDate = `${day}-${month}-${year}`;
        console.log("date", formattedDate);
        // Update the recipts_date field in formData when the DatePicker changes
        setFormData((prevState) => ({
            ...prevState,
            shift_start_date: modifiedDate,
        }));
      };
    
      const handleEndDateChange = (date) => {
        const modifiedDate = new Date(date);
        modifiedDate.setHours(0, 0, 0, 0);
        // Format the date to "dd-mm-yyyy"
        const day = String(modifiedDate.getDate()).padStart(2, '0');
        const month = String(modifiedDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = String(modifiedDate.getFullYear());
    
        const formattedDate = `${day}-${month}-${year}`;
        console.log("date", formattedDate);
        // Update the recipts_date field in formData when the DatePicker changes
        setFormData((prevState) => ({
            ...prevState,
            shift_end_date: modifiedDate,
        }));
      };

      const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(formData => ({
          ...formData,
          [name]: value
        }));
      };

      const imageUrl = `https://app.packersbilling.in/api/${fetchcompanyLogo.data?.[0]?.path}`;
     const [base64URL, setBase64URL] = useState('');
    useEffect(() => {
      const fetchImage2 = async () => {
      try {
          const response = await fetch(imageUrl);
          if (!response.ok) {
          throw new Error(`Failed to fetch image. Status: ${response.status}`);
          }
          const blob = await response.blob();
          const reader = new FileReader();
          reader.onloadend = () => {
          const base64 = reader.result;
          setBase64URL(base64);
          };
          reader.readAsDataURL(blob);
      } catch (error) {
          console.error('Error fetching or converting the image:', error);
      }
      };

      fetchImage2();
  }, [imageUrl]);

    const handleDownloadPdf = () => {
      const element = document.getElementById('content-to-pdf');
      html2pdf(element, {
        margin: 10,
        filename: 'quotation.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      });
    };

  return (
    <>
    {loading && <div className='text-center mt-4'>
        <Loader />
    </div>}
    {!loading && (
    <Row>
        {fetchQutReducers.data && Array.isArray(fetchQutReducers.data) && fetchQutReducers.data.length > 0 ? (
            [...fetchQutReducers.data].reverse().slice(0,1).map(item => (
        <div key={item.id} className="container-fluid invoice-container"> 
            <div id='content-to-pdf'>
                <header>
                    <div className="row align-items-center gy-3">
                        <div className="col-sm-3 text-center text-sm-start">
                            <div>
                                {base64URL ? (
                                    <div>
                                    <img src={base64URL} alt="logo" className='logo'/>
                                    </div>
                                ) : (
                                    <Link to="/update-company" className='btn btn-primary'>Add Logo</Link>
                                )}
                            </div>
                        </div>
                        <div className="col-sm-9 text-center text-sm-end">
                            <h4 className="mb-1">{company_name}</h4>
                            <p className="mb-1"><i className='fa fa-map-marker-alt'></i> {company_add}</p>
                            <div className='d-flex align-items-center justify-content-end mb-1'>
                                <p className="mb-0 mx-2 email"><i className='fa fa-envelope'></i> {email}</p>
                                <p className="mb-0"><i className='fa fa-phone'></i> +91 {phone_number} {phone_number_2 && ( // Check if phone_number_2 exists
                                    <span>, {phone_number_2}</span>
                                )}</p>
                            </div>
                            <div className='d-flex align-items-center justify-content-end'>
                                {company_GST && ( // Check if company_GST exists
                                    <p className="mb-0 mx-2"><i className='fa fa-file'></i> GSTIN : {company_GST}</p>
                                )}
                                {company_website && ( // Check if company_GST exists
                                    <p className="mb-0 text-lowercase"><i className='fa fa-globe-americas'></i> {company_website}</p>
                                )}
                            
                            </div>
                        </div>
                    </div>
                    <hr/>
                </header>
                <main>
                    <div className="row gy-3">
                    <div className="col mt-2 mb-2"> <strong>Quotation Number:</strong> <span>{item.quotation_number}</span> </div>
                    <div className="col text-sm-center mt-2 mb-2"> <strong>Service :</strong> <span>{item.service_name}</span> </div>
                    <div className="col text-sm-end mt-2 mb-2"> <strong>Quotation Date:</strong> <span>{format(parseISO(item.quotation_date), 'dd-MM-yyyy')}</span> </div>
                    </div>
                    <hr className='mb-2 mt-0'/>
                    <div className="row">
                        <div className="col-sm-5">
                            <strong className='mb-1 d-block'>Customer Name :</strong>
                            <p>{item.name}</p>
                        </div>
                        <div className="col-sm-3 text-end">
                            <strong className='mb-1 d-block'>Customer Mo :</strong>
                            <p>{item.phone_number}</p>
                        </div>

                        <div className="col-sm-2 text-end">
                            <strong className='mb-1 d-block'>Start Date :</strong>
                            <p>{format(parseISO(item.shift_start_date), 'dd-MM-yyyy')}</p>
                        </div>

                        <div className="col-sm-2 text-end">
                            <strong className='mb-1 d-block'>End Date :</strong>
                            <p>{format(parseISO(item.shift_end_date), 'dd-MM-yyyy')}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-8">
                            <strong className='mb-1 d-block'>Customer Address :</strong>
                            <p>{item.customer_address}</p>
                        </div>
                        <div className="col-sm-2">
                            <strong className='mb-1 d-block'>From :</strong>
                            <p>{item.from_city}</p>
                        </div>

                        <div className="col-sm-2">
                            <strong className='mb-1 d-block'>To :</strong>
                            <p>{item.to_city}</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <strong className='mb-1 d-block'>Shifting Items :</strong>
                            <p>{item.shift_items}</p>
                        </div>
                    </div>
                    
                    <div className="table-responsive">
                    <table className="table table-bordered text-1 mb-0">
                        <tbody>
                            <tr className="bg-light">
                                <td colspan="6" className='pt-2 pb-2 pr-1 pl-2 text-center'><strong>Shifting Charges</strong></td>
                            </tr>
                            <tr>
                                <td><strong>Transportation Charge</strong></td>
                                <td>{item.transportation_charge}</td>
                                <td><strong>Packing Charge</strong></td>
                                <td >{item.packing_charge}</td>
                                <td><strong>Unpacking Charge</strong></td>
                                <td >{item.unpacking_charge}</td>
                            </tr>
                            <tr>
                                <td><strong>Loading Charge</strong></td>
                                <td >{item.loading_charge}</td>
                                <td><strong>Unloading Charge</strong></td>
                                <td >{item.unloading_charge}</td>
                                <td><strong>Insurance Charge</strong></td>
                                <td >{item.insurance_charge}</td>
                            </tr>
                            <tr>
                                
                                <td><strong>Storage Charge</strong></td>
                                <td >{item.storage_charge}</td>
                                <td><strong>Other Charge</strong></td>
                                <td >{item.other_charge}</td>
                                <td><strong>Service Charge</strong></td>
                                <td >{item.service_charge}</td>
                                
                            </tr>
                            <tr>
                                <td><strong>SGST Charge({item.sgst_per}%)</strong></td>
                                <td ><i className='fa fa-indian-rupee'></i> {item.sgst_charge}</td>
                                <td><strong>CGST Charge({item.cgst_per}%)</strong></td>
                                <td ><i className='fa fa-indian-rupee'></i> {item.cgst_charge}</td>
                                <td><strong>IGST Charge ({item.gst_per}%)</strong></td>
                                <td ><i className='fa fa-indian-rupee'></i> {item.gst_charge}</td>
                            </tr>

                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td className='total_amount'><strong>Total</strong></td>
                                <td className='total_amount'><strong><i className='fa fa-indian-rupee'></i> {item.total_amount}</strong></td>
                            </tr>
                        </tbody>
                    </table>
                    </div>
                    
                    <div className='d-flex align-items-center justify-content-between'>
                                <div>
                                <h4 className="text-3 mt-1 mb-1">Bank Details:</h4>
                                    {FetchBankReducers && Array.isArray(FetchBankReducers.data) && FetchBankReducers.data.length > 0 ? (
                                    [...FetchBankReducers.data].reverse().map(item => (
                                    <div key={item.id} class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-0">
                                        
                                        <div class="d-flex justify-content-between align-items-center mt-3">
                                            <p class="text-dark profile_name">Account Holder Name :</p> <h6 class="text-dark profile_name_data mx-2">{item.account_holder_name}</h6>
                                        </div>
                                        <div class="d-flex justify-content-between align-items-center">
                                            <p class="text-dark profile_name">Bank Name :</p>
                                            <h6 class="text-dark profile_name_data mx-2">{item.bank_name}</h6>
                                        </div>
                                        <div class="d-flex justify-content-between align-items-center">
                                            <p class="text-dark profile_name">Account Number:</p>
                                            <h6 class="text-dark profile_name_data mx-2">{item.account_number}</h6>
                                        </div>
                                        <div class="d-flex justify-content-between align-items-center">
                                            <p class="text-dark profile_name">IFFC Code :</p>
                                            <h6 class="text-dark profile_name_data mx-2">{item.iffc_code}</h6>
                                        </div>
                                    </div>
                                    ))
                                    ) : (
                                        <div className='text-left not_data mt-3 mb-3'>
                                            <Link to="/update-company" className='btn btn-primary'>Add Bank Details</Link>
                                        </div>
                                    )} 
                                </div>
                                <div className='text-end'>
                                    <h4 className="text-3 mt-4 stemp"><strong>For :</strong> {company_name}</h4>
                                    {[...signatures.data].map((signature, index) => (
                                        <img key={index} src={signature.signature_data} alt={`Signature ${index + 1}`} className='sign-size' />
                                    ))}
                                    {!hasSignature && (
                                        <Link to="/update-company" className='d-print-none btn btn-primary'>
                                            Add Sign
                                        </Link>
                                    )}
                                    <p className='mb-0 stemp'>Authorized Signature</p>
                                </div>
                            </div>

                            <div className='d-flex align-items-center justify-content-between'>
                                <div>
                                    <h4 className="text-3 mt-3">Terms and conditions:</h4>
                                    {FeatchTearmsReducers && Array.isArray(FeatchTearmsReducers.data) && FeatchTearmsReducers.data.length > 0 ? (
                                    [...FeatchTearmsReducers.data].reverse().map(item => (
                                        <h6 className="text-dark profile_name_data_terms printpage" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.tearms) }} />
                                        ))
                                        ) : (
                                            <div className='text-center not_data mt-3'>
                                            <Link to="/update-company" className='d-print-none btn btn-primary mb-3'>Add Tearms+</Link>
                                            </div>
                                        )} 
                                </div>
                            </div>
                </main>
            </div>
            <div className="text-center invoice_design">
                <button
                    onClick={() => item && UpdateQuotationClick(item.id)}
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#staticBackdrop"
                    aria-controls="staticBackdrop" className='btn btn-info bill_btn pt-2 pb-2 d-print-none'>
                    Edit
                </button>

                <div className="btn-group btn-group-sm d-print-none">
                    <a href="javascript:window.print()" className="btn btn-info quot_btn pt-2 pb-2"><i className="fa fa-print"></i> Print & Download</a> 
                </div>

                <button
                onClick={handleDownloadPdf}
                type="button"
                className='btn btn-info bill_btn pt-2 pb-2 d-print-none'>
                    Download as PDF
                </button>

                <Link class="btn btn-info plus_btn text-white pt-2 pb-2 d-print-none" to="/quotationList-list">Done</Link>
            </div>
            </div>
        ))
        ): (
            <div className='text-center not_data mt-3'>
                <i class="fa-solid fa-triangle-exclamation"></i>
                <p>No data available</p>
            </div>
        )}
    </Row>
    )}
           {/* Edit Quotation Sidebar Start*/}
           <div class="offcanvas offcanvas-start" data-bs-backdrop="static" tabIndex="-1" id="staticBackdrop" aria-labelledby="staticBackdropLabel" style={{ zIndex: 99999999999 }}>
                <div class="offcanvas-header bg-info">
                    <h5 class="offcanvas-title text-white" id="staticBackdropLabel">Edit Invoice</h5>
                    <button type="button" ref={ref2} class="btn-close btn-close2" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">
                    <div>
                        <Form onSubmit={UpdateQuotation}>
                            <Row className="mb-3">
                            <h6 className='mb-0'>General Details:</h6>
                                <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12">
                                    <hr className='w-10'/>
                                </Col>

                                <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="formGridEmail" className='mt-2'>
                                    <Form.Label>Customer Name</Form.Label>
                                        <Form.Control type="text" onChange={handleChange} placeholder="Customer Name.." name="name" value={formData.name} required/>
                                        {error_edit?.name && (
                                        <span className="alert alert-danger d-block mt-1 mb-1 pt-1 pb-1">
                                            {error_edit.name && error_edit.name}
                                        </span>
                                        )}
                                </Form.Group>

                                <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="formGridPassword" className='mt-2'>
                                        <Form.Label>Phone</Form.Label>
                                            <Form.Control type="number" placeholder="Customer Phone.." onChange={handleChange} name="phone_number" value={formData.phone_number} required/>

                                            {error_edit?.phone_greater && (
                                            <span className="alert alert-danger d-block mt-1 mb-1 pt-1 pb-1">
                                            {error_edit.phone_greater && error_edit.phone_greater}
                                            </span>
                                            )}

                                            {error_edit?.phone_less && (
                                                <span className="alert alert-danger d-block mt-1 mb-1 pt-1 pb-1">
                                                {error_edit.phone_less && error_edit.phone_less}
                                                </span>
                                            )}
                                </Form.Group>

                                <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="formGridPassword" className='mt-2'>
                                    <Form.Label>Bill Date</Form.Label>
                                            <div className="date-picker-container">
                                                <i className='calendar-icon fa fa-calendar'></i>
                                                <DatePicker
                                                    selected={formData.quotation_date}
                                                    onChange={handleQuoteDateChange}
                                                    dateFormat="dd-MM-yyyy"
                                                    placeholderText="Quotation Date"
                                                    className='form-control'
                                                    required
                                                />
                                            </div>
                                </Form.Group>

                                <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="exampleForm.ControlTextarea1" className='mt-2'>
                                    <Form.Label>Customer Address</Form.Label>
                                    <Form.Control as="textarea" rows={3} placeholder="Customer Address.." onChange={handleChange} name="customer_address" value={formData.customer_address} required/>
                                    </Form.Group>

                                    <h6 className='mb-0 mt-3'>Shifting Details:</h6>
                                        <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12">
                                            <hr className='w-10'/>
                                        </Col>

                                    <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="formGridEmail" className='mt-2'>
                                        <Form.Label>Service Name</Form.Label>
                                        <Form.Control type="text" placeholder="Service Name.." onChange={handleChange} name="service_name" value={formData.service_name} required/>
                                    </Form.Group>

                                    <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridEmail" className='mt-2'>
                                        <Form.Label>From City/Area</Form.Label>
                                        <Form.Control type="text" placeholder="From.." onChange={handleChange} name="from_city" value={formData.from_city} required/>
                                    </Form.Group>

                                    <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridEmail" className='mt-2'>
                                        <Form.Label>To City/Area</Form.Label>
                                        <Form.Control type="text" placeholder="To.." onChange={handleChange} name="to_city" value={formData.to_city} required/>
                                    </Form.Group>

                                    <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                        <Form.Label>Shifting Start Date</Form.Label>
                                            <div className="date-picker-container">
                                                <i className='calendar-icon fa fa-calendar'></i>
                                                <DatePicker
                                                    selected={formData.shift_start_date}
                                                    onChange={handleStartDateChange}
                                                    dateFormat="dd-MM-yyyy"
                                                    placeholderText="Start Date"
                                                    className='form-control'
                                                    required
                                                />
                                            </div>
                                        </Form.Group>

                                        <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                            <Form.Label>Shifting End Date</Form.Label>
                                            <div className="date-picker-container">
                                                <i className='calendar-icon fa fa-calendar'></i>
                                                <DatePicker
                                                    selected={formData.shift_end_date}
                                                    onChange={handleEndDateChange}
                                                    dateFormat="dd-MM-yyyy"
                                                    placeholderText="End Date"
                                                    className='form-control'
                                                    required
                                                />
                                            </div>
                                            {error_edit?.shift_end_date_final && (
                                            <span className="alert alert-danger d-block mt-1 mb-1 pt-1 pb-1">
                                            {error_edit.shift_end_date_final && error_edit.shift_end_date_final}
                                            </span>
                                            )}
                                        </Form.Group>

                                            <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="exampleForm.ControlTextarea1" className='mt-2'>
                                                <Form.Label>Shifting Items</Form.Label>
                                                <Form.Control as="textarea" rows={3} placeholder="Shifting Items.." onChange={handleChange} name="shift_items" value={formData.shift_items} required/>
                                            </Form.Group>

                                            <h6 className='mb-0 mt-3'>Shifting Charges :</h6>
                                            <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12">
                                                <hr className='w-10'/>
                                            </Col>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword"  className='mt-2'>
                                                <Form.Label>Transportation Charge</Form.Label>
                                                <Form.Control type="number" placeholder="Transportation Charge.." onChange={handleChange} name="transportation_charge" value={formData.transportation_charge} required/>
                                            </Form.Group>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword"  className='mt-2'>
                                                <Form.Label>Packing Charge</Form.Label>
                                                <Form.Control type="number" placeholder="Packing Charge.." onChange={handleChange} name="packing_charge" value={formData.packing_charge} required/>
                                            </Form.Group>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword"  className='mt-2'>
                                                <Form.Label>Unpacking Charge</Form.Label>
                                                <Form.Control type="number" placeholder="Unpacking Charge.." onChange={handleChange} name="unpacking_charge" value={formData.unpacking_charge} required/>
                                            </Form.Group>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                                <Form.Label>Loading Charge</Form.Label>
                                                <Form.Control type="number" onChange={handleChange} placeholder="Loading Charge.." name="loading_charge" value={formData.loading_charge} required/>
                                            </Form.Group>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                                <Form.Label>Unloading Charge</Form.Label>
                                                <Form.Control type="number" placeholder="Unloading Charge.." onChange={handleChange} name="unloading_charge" value={formData.unloading_charge} required/>
                                            </Form.Group>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                                <Form.Label>Insurance Charge</Form.Label>
                                                <Form.Control type="number" placeholder="Insurance Charge.." onChange={handleChange} name="insurance_charge" value={formData.insurance_charge} required/>
                                            </Form.Group>

                                            

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword"  className='mt-2'>
                                                <Form.Label>Storage Charge</Form.Label>
                                                <Form.Control type="number" placeholder="Storage Charge.." onChange={handleChange} name="storage_charge" value={formData.storage_charge} required/>
                                            </Form.Group>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                                <Form.Label>Other Charge</Form.Label>
                                                <Form.Control type="number" placeholder="Other Charge.." onChange={handleChange} name="other_charge" value={formData.other_charge} required/>
                                            </Form.Group>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                                <Form.Label>Total</Form.Label>
                                                <Form.Control type="number" placeholder="Total.." name="total_charge" value={calculateTotal()} readOnly/>
                                            </Form.Group>

                                            <h6 className='mb-0 mt-3'>Tax Charges :</h6>
                                            <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12">
                                                <hr className='w-10'/>
                                            </Col>

                                            <div className='form-group mt-2 col-md-6 col-lg-6 col-sm-12 col-xs-12'>
                                            <Form.Label for="exampleSelect2">Select SGST %</Form.Label>
                                            <select className='form-select' id="exampleSelect2" onChange={handleChange} name="sgst_per" value={formData.sgst_per} required>
                                                <option value="">Select % </option>
                                                <option value="0">0%</option>
                                                <option value="1">1%</option>
                                                <option value="2">2%</option>
                                                <option value="2.5">2.5%</option>
                                                <option value="3">3%</option>
                                                <option value="3.5">3.5%</option>
                                                <option value="4">4%</option>
                                                <option value="4.5">4.5%</option>
                                                <option value="5">5%</option>
                                                <option value="5.5">5.5%</option>
                                                <option value="6">6%</option>
                                                <option value="6.5">6.5%</option>
                                                <option value="7">7%</option>
                                                <option value="7.5">7.5%</option>
                                                <option value="8">8%</option>
                                                <option value="8.5">8.5%</option>
                                                <option value="9">9%</option>
                                                <option value="9.5">9.5%</option>
                                                <option value="10">10%</option>
                                                <option value="10.5">10.5%</option>
                                                <option value="11">11%</option>
                                                <option value="11.5">11.5%</option>
                                                <option value="12">12%</option>
                                                <option value="12.5">12.5%</option>
                                                <option value="13">13%</option>
                                                <option value="13.5">13.5%</option>
                                                <option value="14">14%</option>
                                                <option value="14.5">14.5%</option>
                                            </select>
                                            {error_edit?.sgst_per && (
                                            <span className="alert alert-danger d-block mb-1 mt-1 pt-1 pb-1">
                                                {error_edit.sgst_per && error_edit.sgst_per}
                                            </span>
                                            )}
                                        </div>

                                        <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                            <Form.Label>SGST Charge</Form.Label>
                                            <Form.Control type="number" className='readonly-input' placeholder="SGST Charge.." name="sgst_charge" value={formData.sgst_charge} readOnly/>
                                        </Form.Group>

                                            <div className='form-group mt-2 col-md-6 col-lg-6 col-sm-12 col-xs-12'>
                                            <Form.Label for="exampleSelect2">Select CGST %</Form.Label>
                                                <select className='form-select' id="exampleSelect2" onChange={handleChange} name="cgst_per" value={formData.cgst_per} required>
                                                    <option value="">Select % </option>
                                                    <option value="0">0%</option>
                                                    <option value="1">1%</option>
                                                    <option value="2">2%</option>
                                                    <option value="2.5">2.5%</option>
                                                    <option value="3">3%</option>
                                                    <option value="3.5">3.5%</option>
                                                    <option value="4">4%</option>
                                                    <option value="4.5">4.5%</option>
                                                    <option value="5">5%</option>
                                                    <option value="5.5">5.5%</option>
                                                    <option value="6">6%</option>
                                                    <option value="6.5">6.5%</option>
                                                    <option value="7">7%</option>
                                                    <option value="7.5">7.5%</option>
                                                    <option value="8">8%</option>
                                                    <option value="8.5">8.5%</option>
                                                    <option value="9">9%</option>
                                                    <option value="9.5">9.5%</option>
                                                    <option value="10">10%</option>
                                                    <option value="10.5">10.5%</option>
                                                    <option value="11">11%</option>
                                                    <option value="11.5">11.5%</option>
                                                    <option value="12">12%</option>
                                                    <option value="12.5">12.5%</option>
                                                    <option value="13">13%</option>
                                                    <option value="13.5">13.5%</option>
                                                    <option value="14">14%</option>
                                                    <option value="14.5">14.5%</option>
                                                </select>
                                                {error_edit?.cgst_per && (
                                                <span className="alert alert-danger d-block mt-1 mb-1 pt-1 pb-1">
                                                    {error_edit.cgst_per && error_edit.cgst_per}
                                                </span>
                                            )}
                                            </div>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                                <Form.Label>CGST Charge</Form.Label>
                                                <Form.Control type="number" className='readonly-input' placeholder="CGST Charge.." name="cgst_charge" value={formData.cgst_charge} readOnly/>
                                            </Form.Group>
                                            
                                            <div className='form-group mt-2 col-md-6 col-lg-6 col-sm-12 col-xs-12'>
                                            <Form.Label for="exampleSelect">Select IGST %</Form.Label>
                                            <select className='form-select' id="exampleSelect" onChange={handleChange} name="gst_per" value={formData.gst_per} required>
                                            <option value="">Select % </option>
                                            <option value="0">0%</option>
                                                <option value="1">1%</option>
                                                <option value="2">2%</option>
                                                <option value="2.5">2.5%</option>
                                                <option value="3">3%</option>
                                                <option value="3.5">3.5%</option>
                                                <option value="4">4%</option>
                                                <option value="4.5">4.5%</option>
                                                <option value="5">5%</option>
                                                <option value="5.5">5.5%</option>
                                                <option value="6">6%</option>
                                                <option value="6.5">6.5%</option>
                                                <option value="7">7%</option>
                                                <option value="7.5">7.5%</option>
                                                <option value="8">8%</option>
                                                <option value="8.5">8.5%</option>
                                                <option value="9">9%</option>
                                                <option value="9.5">9.5%</option>
                                                <option value="10">10%</option>
                                                <option value="10.5">10.5%</option>
                                                <option value="11">11%</option>
                                                <option value="11.5">11.5%</option>
                                                <option value="12">12%</option>
                                                <option value="12.5">12.5%</option>
                                                <option value="13">13%</option>
                                                <option value="13.5">13.5%</option>
                                                <option value="14">14%</option>
                                                <option value="14.5">14.5%</option>
                                                <option value="15">15%</option>
                                                <option value="16">16%</option>
                                                <option value="17">17%</option>
                                                <option value="18">18%</option>
                                                <option value="19">19%</option>
                                                <option value="20">20%</option>
                                                <option value="21">21%</option>
                                                <option value="22">22%</option>
                                                <option value="23">23%</option>
                                                <option value="24">24%</option>
                                                <option value="25">25%</option>
                                                <option value="26">26%</option>
                                                <option value="27">27%</option>
                                                <option value="28">28%</option>
                                            </select>
                                            {error_edit?.gst_per && (
                                            <span className="alert alert-danger d-block mt-1 mb-1 pt-1 pb-1">
                                                {error_edit.gst_per && error_edit.gst_per}
                                            </span>
                                            )}
                                        </div>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                                <Form.Label>IGST Charge</Form.Label>
                                                <Form.Control type="number" className='readonly-input' placeholder="GST Charge.." name="gst_charge" value={formData.gst_charge} readOnly/>
                                            </Form.Group>

                                            
                                            
                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                                <Form.Label>Service Charge</Form.Label>
                                                <Form.Control type="number" placeholder="Service Charge.." onChange={handleChange} name="service_charge" value={formData.service_charge} required/>
                                            </Form.Group>
                                            
                                        </Row>

                                        <Button variant="primary" type="submit" className='d-flex align-items-center justify-content-center'>
                                            <span className='mx-2'>Update</span>
                                            {loading_update && <span className='spin-sm'><Loader /></span>}
                                        </Button>
                        </Form>
                    </div>
                </div>
            </div>
            {/* Edit Quotation Sidebar End */}
    </>
  )
}

export default Quotedesign

