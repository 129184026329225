import React, { useEffect, useRef, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useDispatch, useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Swal from 'sweetalert2';
import FixedButton from '../FixedButton';
import MobileNavigation from '../MobileNavigation';
import { fatchQuotationAPI,DeleteQuotationAPI,EditQuotationAPI} from '../../redux/actionCreation';
import DatePicker from 'react-datepicker';
import { parseISO, format } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../Loader';

function QuotationList() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [loading_update, setLoading_update] = useState(false);
    useEffect(()=>{
        if(!localStorage.getItem('tokan')){
            navigate('/login');
        }
    }, []);
    
    const dispatch = useDispatch();
    let quotationtCounter = 0;
    const ref = useRef(null);
    const ref2 = useRef(null);
    const sidebarState = useSelector(state => state.sidebarReducres);
    const fetchQutReducers = useSelector(state => state.fetchQutReducers);
    const [selectedQuotId, setSelectedQuotId] = useState(null);
    const [filteredQuote, setFilteredQuote] = useState([]);
    const error_edit = useSelector(state => state.EditQuotationReducers.error?.edit_quotation_errors) ?? null;
    
     // Update Quotation Function
     const [formData, setFormData] = useState({  
        name: '',
        phone_number: '',
        quotation_date: '',
        customer_address: '',
        service_name: '',
        from_city: '',
        to_city: '',
        shift_start_date: '',
        shift_end_date: '',
        shift_items: '',
        transportation_charge: '',
        packing_charge: '',
        unpacking_charge: '',
        loading_charge: '',
        unloading_charge: '',
        insurance_charge: '',
        service_charge: '',
        storage_charge: '',
        other_charge: '',
        total_charge: '',
        sgst_per: '',
        sgst_charge: '',
        cgst_per: '',
        cgst_charge: '',
        gst_per: '',
        gst_charge: '',
      });

        // Function to calculate total of specified fields
   const calculateTotal = () => {
    const {
      transportation_charge,
      packing_charge,
      unpacking_charge,
      loading_charge,
      unloading_charge,
      insurance_charge,
      storage_charge,
      other_charge
    } = formData;

    const total =
      parseFloat(transportation_charge) +
      parseFloat(packing_charge) +
      parseFloat(unpacking_charge) +
      parseFloat(loading_charge) +
      parseFloat(unloading_charge) +
      parseFloat(insurance_charge) +
      parseFloat(storage_charge) +
      parseFloat(other_charge);
    return total;
  };

    // Function to calculate SGST charge based on total and cgst_per
    const calculateSGSTCharge = (total, sgstPercentage) => {
        return (total * parseFloat(sgstPercentage)) / 100;
    };

    // Function to calculate CGST charge based on total and cgst_per
    const calculateCGSTCharge = (total, cgstPercentage) => {
        return (total * parseFloat(cgstPercentage)) / 100;
    };
    
    // Function to calculate IGST charge based on total and gst_per
    const calculateGSTCharge = (total, gstPercentage) => {
        return (total * parseFloat(gstPercentage)) / 100;
    };
  
    // useEffect to watch for changes in relevant fields and recalculate charges
    useEffect(() => {
        const total = calculateTotal();
        const gstCharge = calculateGSTCharge(total, formData.gst_per);
        const cgstCharge = calculateCGSTCharge(total, formData.cgst_per);
        const sgstCharge = calculateSGSTCharge(total, formData.sgst_per);
        // Update state with the calculated values
        setFormData((prevData) => ({
        ...prevData,
        total_charge:total.toFixed(2),
        gst_charge: gstCharge.toFixed(2), // Adjust decimal places as needed
        cgst_charge: cgstCharge.toFixed(2), // Adjust decimal places as needed
        sgst_charge: sgstCharge.toFixed(2), // Adjust decimal places as needed
        }));
    }, [
        formData.transportation_charge,
        formData.packing_charge,
        formData.unpacking_charge,
        formData.loading_charge,
        formData.unloading_charge,
        formData.insurance_charge,
        formData.storage_charge,
        formData.other_charge,
        formData.sgst_per,
        formData.cgst_per,
        formData.gst_per,
    ]);
  
    useEffect(() => {
        setFilteredQuote(fetchQutReducers.data);
  }, [fetchQutReducers.data]); 
  
  // Datewise Filter Code Start
  const handleFormSubmit = (event) => {
    event.preventDefault();
    const newFilteredQuotation = fetchQutReducers.data.filter((item) => {
        // Check date range
        const itemDate = new Date(item.quotation_date);
        // Function to format the date as dd-mm-yyyy
        function formatDate(date) {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
        }
        const formattedDate = formatDate(itemDate);

        const isWithinRange =
        (!formdate || itemDate >= formdate) &&
        (!todate || itemDate <= new Date(todate.getTime() + 86400000)); // 86400000 milliseconds in a day

        return isWithinRange;
    });
    setFilteredQuote(newFilteredQuotation);
};
// Datewise Filter Code End


      const formatDate = (dateString) => {
        const date = new Date(dateString);
        const formattedDate = date.toISOString().split('T')[0];
        return formattedDate;
    };

    // Function to handle link click
    const UpdateQuotationClick = (QuoteId) => {
        setSelectedQuotId(QuoteId);
        const selectedQuote = fetchQutReducers.data.find(quotation => quotation.id === QuoteId);
        if (selectedQuote) {
                const formattedDate = formatDate(selectedQuote.quotation_date);
                const shift_start_date = formatDate(selectedQuote.shift_start_date);
                const shift_end_date = formatDate(selectedQuote.shift_end_date);
                const defaultTime = new Date();
                defaultTime.setHours(0, 0, 0, 0);
            setFormData({
                ...formData,
                name: selectedQuote.name,
                phone_number: selectedQuote.phone_number,
                quotation_date: new Date(formattedDate + 'T00:00:00'),
                customer_address: selectedQuote.customer_address,
                service_name: selectedQuote.service_name,
                from_city: selectedQuote.from_city,
                to_city: selectedQuote.to_city,
                shift_start_date: new Date(shift_start_date + 'T00:00:00'),
                shift_end_date: new Date(shift_end_date + 'T00:00:00'),
                shift_items: selectedQuote.shift_items,
                transportation_charge: selectedQuote.transportation_charge,
                packing_charge: selectedQuote.packing_charge,
                unpacking_charge: selectedQuote.unpacking_charge,
                loading_charge: selectedQuote.loading_charge,
                unloading_charge: selectedQuote.unloading_charge,
                insurance_charge: selectedQuote.insurance_charge,
                service_charge: selectedQuote.service_charge,
                storage_charge: selectedQuote.storage_charge,
                other_charge: selectedQuote.other_charge,
                sgst_per: selectedQuote.sgst_per,
                sgst_charge: selectedQuote.sgst_charge,
                cgst_per: selectedQuote.cgst_per,
                cgst_charge: selectedQuote.cgst_charge,
                gst_per: selectedQuote.gst_per,
                gst_charge: selectedQuote.gst_charge,
              });
        } else {
            console.log("Quotation not found");
        }
    };
    
    const UpdateQuotation = async (event) => {
        event.preventDefault();
        setLoading_update(true);
        try {
            const response = await dispatch(EditQuotationAPI(formData, selectedQuotId));
            setLoading_update(false);
            if(response?.type == "ADD_DATA_SUCCESS_EDIT_QUOTATION"){
                ref2.current.click();
                await Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'Quotation updated successfully.',
                    customClass: {
                        container: 'my-custom-swal', // Add your custom class here
                    },
                });
               await dispatch(fatchQuotationAPI());
            }
              else {
              // Show error SweetAlert
                await Swal.fire({
                    icon: 'error',
                    title: 'Some Error',
                    text: 'Failed to update the Quotation. Please try again.',
                });
            }
              
        } catch (error) {
          console.error('Error updating Quotation:', error);
        }
      };

      const handleQuoteDateChange = (date) => {
        const modifiedDate = new Date(date);
        modifiedDate.setHours(0, 0, 0, 0);
        // Format the date to "dd-mm-yyyy"
        const day = String(modifiedDate.getDate()).padStart(2, '0');
        const month = String(modifiedDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = String(modifiedDate.getFullYear());
    
        const formattedDate = `${day}-${month}-${year}`;
        console.log("date", formattedDate);
        // Update the recipts_date field in formData when the DatePicker changes
        setFormData((prevState) => ({
            ...prevState,
            quotation_date: modifiedDate,
        }));
        };
    
      const handleStartDateChange = (date) => {
        const modifiedDate = new Date(date);
        modifiedDate.setHours(0, 0, 0, 0);
        // Format the date to "dd-mm-yyyy"
        const day = String(modifiedDate.getDate()).padStart(2, '0');
        const month = String(modifiedDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = String(modifiedDate.getFullYear());
    
        const formattedDate = `${day}-${month}-${year}`;
        console.log("date", formattedDate);
        // Update the recipts_date field in formData when the DatePicker changes
        setFormData((prevState) => ({
            ...prevState,
            shift_start_date: modifiedDate,
        }));
      };
    
      const handleEndDateChange = (date) => {
        const modifiedDate = new Date(date);
        modifiedDate.setHours(0, 0, 0, 0);
        // Format the date to "dd-mm-yyyy"
        const day = String(modifiedDate.getDate()).padStart(2, '0');
        const month = String(modifiedDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = String(modifiedDate.getFullYear());
    
        const formattedDate = `${day}-${month}-${year}`;
        console.log("date", formattedDate);
        // Update the recipts_date field in formData when the DatePicker changes
        setFormData((prevState) => ({
            ...prevState,
            shift_end_date: modifiedDate,
        }));
      };

      const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(formData => ({
          ...formData,
          [name]: value
        }));
      };

       // Delete Quotation Function
       const DeleteQuotation = async (QuoteId) => {
        // Show confirmation SweetAlert
        const result = await Swal.fire({
            title: `Are you sure Delete Quotation ?`,
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#673ab7',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        });
    
        if (result.isConfirmed) {
            try {
                await dispatch(DeleteQuotationAPI(QuoteId));
                dispatch(fatchQuotationAPI());
            } catch (error) {
            //   console.error('Error:', error);
            }
        } else {
            // User clicked "Cancel" or closed the SweetAlert
            console.log('Deletion cancelled.');
        }
    };


    const [formdate, setFormDate] = useState(null);
    const [todate, setToDate] = useState(null);
    const fromDate = (date) => {
        setFormDate(date);
      };
      const toDate = (date) => {
        setToDate(date);
      };
    

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const res = await dispatch(fatchQuotationAPI());
                setLoading(false);
                // Process the result if needed
            } catch (error) {
                setLoading(false);
                console.log('Error fetching data:', error);
            }
        };
        fetchData(); // Call the async function
    }, []);


  return (
    <>
        <FixedButton/>
        <div className={`main-content ${!sidebarState ? '' : 'navbar-ful'}`}>
            <div className='page-content'>
                <Container fluid>
                    <Row>
                        <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12">
                            <h5 className='mb-1'>Search Quotation</h5>
                        </Col>
                    </Row>

                    <Form onSubmit={handleFormSubmit}>
                        <Row className='search-list'>
                            <Col xs="6" sm="6" md="2" lg="2" xl="2" xxl="2" className="mt-2">
                                <div className="date-picker-container">
                                    <i className='calendar-icon fa fa-calendar'></i>
                                    <DatePicker
                                        selected={formdate}
                                        onChange={fromDate}
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="From Date"
                                        className='form-control'
                                    />
                                </div>
                            </Col>

                            <Col xs="6" sm="6" md="2" lg="2" xl="2" xxl="2" className="mt-2">
                                <div className="date-picker-container">
                                    <i className='calendar-icon fa fa-calendar'></i>
                                    <DatePicker
                                        selected={todate}
                                        onChange={toDate}
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="To Date"
                                        className='form-control'
                                    />
                                </div>
                            </Col>

                            {/* <Col xs="6" sm="6" md="2" lg="3" xl="3" xxl="3" className="mt-2">
                                <Form.Group className="" onChange={cut_num} controlId="formGroupEmail">
                                    <Form.Control type="number" min={0} placeholder="Customer Number" />
                                </Form.Group>
                            </Col> */}

                            {/* <Col xs="6" sm="6" md="2" lg="3" xl="3" xxl="3" className="mt-2">
                                <Form.Group className="" controlId="formGroupEmail">
                                    <Form.Control type="text" onChange={cut_name} placeholder="Customer Name" />
                                </Form.Group>
                            </Col> */}
                            
                            <Col xs="6" sm="6" md="2" lg="2" xl="2" xxl="2" className="mt-2">
                                <Button variant="primary" type="submit" className="">
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                    </Form>

                    <Row>
                        <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" className='mt-4'>
                            <h5 className='mb-3'>QuotationList List</h5>
                        </Col>
                    </Row>
                    <div className='text-center'>
                        {loading && <Loader />}
                    </div>

                    {!loading && (
                    <Row>
                        {filteredQuote && Array.isArray(filteredQuote) && filteredQuote.length > 0 ? (
                        [...filteredQuote].reverse().map(item => (
                        <Col key={item.id} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6">
                            <div className="dash-count bill_box das3">
                                <div className="dash-counts">
                                    <h4 className="text-dark c_name">{item.name}</h4>
                                    <h5 className="text-dark text-green">Quotation #{item.quotation_number}</h5>
                                    {item.quotation_date && (
                                        <h5 className="text-dark">
                                        {format(parseISO(item.quotation_date), 'dd-MM-yyyy')}
                                        </h5>
                                    )}
                                </div>
                                <div className="dash-imgs">
                                    <i className="text-dark invoice_i fa fa-indian-rupee-sign mr-0"></i>
                                    <span className="amount text-dark">{item.total_amount}</span>
                                        <p>
                                            <Link to={`/quotationedit/${item.id}`}>
                                                <i className='view_invoice_i pr-3 fa fa-eye'></i>
                                            </Link>

                                            <Link onClick={() => UpdateQuotationClick(item.id)}  type="button" data-bs-toggle="offcanvas" data-bs-target="#staticBackdrop" aria-controls="staticBackdrop">
                                                <i className='view_invoice_i pr-3 fa fa-edit'></i>
                                            </Link>

                                            <Link to="#" onClick={() => DeleteQuotation(item.id)}>
                                                <i className='delet_invoice_i pr-3 fa fa-trash'></i>
                                            </Link>
                                        </p>
                                </div>
                            </div>
                            </Col>
                        ))
                        ) : (
                        <div className='text-center not_data mt-3'>
                            <i class="fa-solid fa-triangle-exclamation"></i>
                            <p>No data available</p>
                        </div>
                        )}
                    </Row>
                    )}
                </Container>
            </div>
            <MobileNavigation/>
            {/* Edit Quotation Sidebar Start*/}
            <div class="offcanvas offcanvas-start" data-bs-backdrop="static" tabIndex="-1" id="staticBackdrop" aria-labelledby="staticBackdropLabel" style={{ zIndex: 99999999999 }}>
                <div class="offcanvas-header bg-info">
                    <h5 class="offcanvas-title text-white" id="staticBackdropLabel">Edit Invoice</h5>
                    <button type="button" ref={ref2} class="btn-close btn-close2" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">
                    <div>
                        <Form onSubmit={UpdateQuotation}>
                            <Row className="mb-3">
                            <h6 className='mb-0'>General Details:</h6>
                                <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12">
                                    <hr className='w-10'/>
                                </Col>

                                <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="formGridEmail" className='mt-2'>
                                    <Form.Label>Customer Name</Form.Label>
                                        <Form.Control type="text" onChange={handleChange} placeholder="Customer Name.." name="name" value={formData.name} required/>
                                        {error_edit?.name && (
                                        <span className="alert alert-danger d-block mt-1 mb-1 pt-1 pb-1">
                                            {error_edit.name && error_edit.name}
                                        </span>
                                        )}
                                </Form.Group>

                                <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="formGridPassword" className='mt-2'>
                                        <Form.Label>Phone</Form.Label>
                                            <Form.Control type="number" min={0} placeholder="Customer Phone.." onChange={handleChange} name="phone_number" value={formData.phone_number} required/>

                                            {error_edit?.phone_greater && (
                                            <span className="alert alert-danger d-block mt-1 mb-1 pt-1 pb-1">
                                            {error_edit.phone_greater && error_edit.phone_greater}
                                            </span>
                                            )}

                                            {error_edit?.phone_less && (
                                                <span className="alert alert-danger d-block mt-1 mb-1 pt-1 pb-1">
                                                {error_edit.phone_less && error_edit.phone_less}
                                                </span>
                                            )}
                                </Form.Group>

                                <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="formGridPassword" className='mt-2'>
                                    <Form.Label>Bill Date</Form.Label>
                                            <div className="date-picker-container">
                                                <i className='calendar-icon fa fa-calendar'></i>
                                                <DatePicker
                                                    selected={formData.quotation_date}
                                                    onChange={handleQuoteDateChange}
                                                    dateFormat="dd-MM-yyyy"
                                                    placeholderText="Quotation Date"
                                                    className='form-control'
                                                    required
                                                />
                                            </div>
                                </Form.Group>

                                <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="exampleForm.ControlTextarea1" className='mt-2'>
                                    <Form.Label>Customer Address</Form.Label>
                                    <Form.Control as="textarea" rows={3} placeholder="Customer Address.." onChange={handleChange} name="customer_address" value={formData.customer_address} required/>
                                    </Form.Group>

                                    <h6 className='mb-0 mt-3'>Shifting Details:</h6>
                                        <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12">
                                            <hr className='w-10'/>
                                        </Col>

                                    <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="formGridEmail" className='mt-2'>
                                        <Form.Label>Service Name</Form.Label>
                                        <Form.Control type="text" placeholder="Service Name.." onChange={handleChange} name="service_name" value={formData.service_name} required/>
                                    </Form.Group>

                                    <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridEmail" className='mt-2'>
                                        <Form.Label>From City/Area</Form.Label>
                                        <Form.Control type="text" placeholder="From.." onChange={handleChange} name="from_city" value={formData.from_city} required/>
                                    </Form.Group>

                                    <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridEmail" className='mt-2'>
                                        <Form.Label>To City/Area</Form.Label>
                                        <Form.Control type="text" placeholder="To.." onChange={handleChange} name="to_city" value={formData.to_city} required/>
                                    </Form.Group>

                                    <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                        <Form.Label>Shifting Start Date</Form.Label>
                                            <div className="date-picker-container">
                                                <i className='calendar-icon fa fa-calendar'></i>
                                                <DatePicker
                                                    selected={formData.shift_start_date}
                                                    onChange={handleStartDateChange}
                                                    dateFormat="dd-MM-yyyy"
                                                    placeholderText="Start Date"
                                                    className='form-control'
                                                    required
                                                />
                                            </div>
                                        </Form.Group>

                                        <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                            <Form.Label>Shifting End Date</Form.Label>
                                            <div className="date-picker-container">
                                                <i className='calendar-icon fa fa-calendar'></i>
                                                <DatePicker
                                                    selected={formData.shift_end_date}
                                                    onChange={handleEndDateChange}
                                                    dateFormat="dd-MM-yyyy"
                                                    placeholderText="End Date"
                                                    className='form-control'
                                                    required
                                                />
                                            </div>
                                            {error_edit?.shift_end_date_final && (
                                            <span className="alert alert-danger d-block mt-1 mb-1 pt-1 pb-1">
                                            {error_edit.shift_end_date_final && error_edit.shift_end_date_final}
                                            </span>
                                            )}
                                        </Form.Group>

                                            <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="exampleForm.ControlTextarea1" className='mt-2'>
                                                <Form.Label>Shifting Items</Form.Label>
                                                <Form.Control as="textarea" rows={3} placeholder="Shifting Items.." onChange={handleChange} name="shift_items" value={formData.shift_items} required/>
                                            </Form.Group>

                                            <h6 className='mb-0 mt-3'>Shifting Charges :</h6>
                                            <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12">
                                                <hr className='w-10'/>
                                            </Col>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword"  className='mt-2'>
                                                <Form.Label>Transportation Charge</Form.Label>
                                                <Form.Control type="number" min={0} placeholder="Transportation Charge.." onChange={handleChange} name="transportation_charge" value={formData.transportation_charge} required/>
                                            </Form.Group>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword"  className='mt-2'>
                                                <Form.Label>Packing Charge</Form.Label>
                                                <Form.Control type="number" min={0} placeholder="Packing Charge.." onChange={handleChange} name="packing_charge" value={formData.packing_charge} required/>
                                            </Form.Group>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword"  className='mt-2'>
                                                <Form.Label>Unpacking Charge</Form.Label>
                                                <Form.Control type="number" min={0} placeholder="Unpacking Charge.." onChange={handleChange} name="unpacking_charge" value={formData.unpacking_charge} required/>
                                            </Form.Group>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                                <Form.Label>Loading Charge</Form.Label>
                                                <Form.Control type="number" min={0} onChange={handleChange} placeholder="Loading Charge.." name="loading_charge" value={formData.loading_charge} required/>
                                            </Form.Group>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                                <Form.Label>Unloading Charge</Form.Label>
                                                <Form.Control type="number" min={0} placeholder="Unloading Charge.." onChange={handleChange} name="unloading_charge" value={formData.unloading_charge} required/>
                                            </Form.Group>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                                <Form.Label>Insurance Charge</Form.Label>
                                                <Form.Control type="number" min={0} placeholder="Insurance Charge.." onChange={handleChange} name="insurance_charge" value={formData.insurance_charge} required/>
                                            </Form.Group>

                                            

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword"  className='mt-2'>
                                                <Form.Label>Storage Charge</Form.Label>
                                                <Form.Control type="number" min={0} placeholder="Storage Charge.." onChange={handleChange} name="storage_charge" value={formData.storage_charge} required/>
                                            </Form.Group>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                                <Form.Label>Other Charge</Form.Label>
                                                <Form.Control type="number" min={0} placeholder="Other Charge.." onChange={handleChange} name="other_charge" value={formData.other_charge} required/>
                                            </Form.Group>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                                <Form.Label>Total</Form.Label>
                                                <Form.Control type="number" min={0} placeholder="Total.." name="total_charge" value={calculateTotal()} readOnly/>
                                            </Form.Group>

                                            <h6 className='mb-0 mt-3'>Tax Charges :</h6>
                                            <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12">
                                                <hr className='w-10'/>
                                            </Col>

                                            <div className='form-group mt-2 col-md-6 col-lg-6 col-sm-12 col-xs-12'>
                                            <Form.Label for="exampleSelect2">Select SGST %</Form.Label>
                                            <select className='form-select' id="exampleSelect2" onChange={handleChange} name="sgst_per" value={formData.sgst_per} required>
                                                <option value="">Select % </option>
                                                <option value="0">0%</option>
                                                <option value="1">1%</option>
                                                <option value="2">2%</option>
                                                <option value="2.5">2.5%</option>
                                                <option value="3">3%</option>
                                                <option value="3.5">3.5%</option>
                                                <option value="4">4%</option>
                                                <option value="4.5">4.5%</option>
                                                <option value="5">5%</option>
                                                <option value="5.5">5.5%</option>
                                                <option value="6">6%</option>
                                                <option value="6.5">6.5%</option>
                                                <option value="7">7%</option>
                                                <option value="7.5">7.5%</option>
                                                <option value="8">8%</option>
                                                <option value="8.5">8.5%</option>
                                                <option value="9">9%</option>
                                                <option value="9.5">9.5%</option>
                                                <option value="10">10%</option>
                                                <option value="10.5">10.5%</option>
                                                <option value="11">11%</option>
                                                <option value="11.5">11.5%</option>
                                                <option value="12">12%</option>
                                                <option value="12.5">12.5%</option>
                                                <option value="13">13%</option>
                                                <option value="13.5">13.5%</option>
                                                <option value="14">14%</option>
                                                <option value="14.5">14.5%</option>
                                            </select>
                                            {error_edit?.sgst_per && (
                                            <span className="alert alert-danger d-block mb-1 mt-1 pt-1 pb-1">
                                                {error_edit.sgst_per && error_edit.sgst_per}
                                            </span>
                                            )}
                                        </div>

                                        <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                            <Form.Label>SGST Charge</Form.Label>
                                            <Form.Control type="number" min={0} className='readonly-input' placeholder="SGST Charge.." name="sgst_charge" value={formData.sgst_charge} readOnly/>
                                        </Form.Group>

                                            <div className='form-group mt-2 col-md-6 col-lg-6 col-sm-12 col-xs-12'>
                                            <Form.Label for="exampleSelect2">Select CGST %</Form.Label>
                                                <select className='form-select' id="exampleSelect2" onChange={handleChange} name="cgst_per" value={formData.cgst_per} required>
                                                    <option value="">Select % </option>
                                                    <option value="0">0%</option>
                                                    <option value="1">1%</option>
                                                    <option value="2">2%</option>
                                                    <option value="2.5">2.5%</option>
                                                    <option value="3">3%</option>
                                                    <option value="3.5">3.5%</option>
                                                    <option value="4">4%</option>
                                                    <option value="4.5">4.5%</option>
                                                    <option value="5">5%</option>
                                                    <option value="5.5">5.5%</option>
                                                    <option value="6">6%</option>
                                                    <option value="6.5">6.5%</option>
                                                    <option value="7">7%</option>
                                                    <option value="7.5">7.5%</option>
                                                    <option value="8">8%</option>
                                                    <option value="8.5">8.5%</option>
                                                    <option value="9">9%</option>
                                                    <option value="9.5">9.5%</option>
                                                    <option value="10">10%</option>
                                                    <option value="10.5">10.5%</option>
                                                    <option value="11">11%</option>
                                                    <option value="11.5">11.5%</option>
                                                    <option value="12">12%</option>
                                                    <option value="12.5">12.5%</option>
                                                    <option value="13">13%</option>
                                                    <option value="13.5">13.5%</option>
                                                    <option value="14">14%</option>
                                                    <option value="14.5">14.5%</option>
                                                </select>
                                                {error_edit?.cgst_per && (
                                                <span className="alert alert-danger d-block mt-1 mb-1 pt-1 pb-1">
                                                    {error_edit.cgst_per && error_edit.cgst_per}
                                                </span>
                                            )}
                                            </div>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                                <Form.Label>CGST Charge</Form.Label>
                                                <Form.Control type="number" min={0} className='readonly-input' placeholder="CGST Charge.." name="cgst_charge" value={formData.cgst_charge} readOnly/>
                                            </Form.Group>
                                            
                                            <div className='form-group mt-2 col-md-6 col-lg-6 col-sm-12 col-xs-12'>
                                            <Form.Label for="exampleSelect">Select IGST %</Form.Label>
                                            <select className='form-select' id="exampleSelect" onChange={handleChange} name="gst_per" value={formData.gst_per} required>
                                                <option value="">Select % </option>
                                                <option value="0">0%</option>
                                                <option value="1">1%</option>
                                                <option value="2">2%</option>
                                                <option value="2.5">2.5%</option>
                                                <option value="3">3%</option>
                                                <option value="3.5">3.5%</option>
                                                <option value="4">4%</option>
                                                <option value="4.5">4.5%</option>
                                                <option value="5">5%</option>
                                                <option value="5.5">5.5%</option>
                                                <option value="6">6%</option>
                                                <option value="6.5">6.5%</option>
                                                <option value="7">7%</option>
                                                <option value="7.5">7.5%</option>
                                                <option value="8">8%</option>
                                                <option value="8.5">8.5%</option>
                                                <option value="9">9%</option>
                                                <option value="9.5">9.5%</option>
                                                <option value="10">10%</option>
                                                <option value="10.5">10.5%</option>
                                                <option value="11">11%</option>
                                                <option value="11.5">11.5%</option>
                                                <option value="12">12%</option>
                                                <option value="12.5">12.5%</option>
                                                <option value="13">13%</option>
                                                <option value="13.5">13.5%</option>
                                                <option value="14">14%</option>
                                                <option value="14.5">14.5%</option>
                                                <option value="15">15%</option>
                                                <option value="16">16%</option>
                                                <option value="17">17%</option>
                                                <option value="18">18%</option>
                                                <option value="19">19%</option>
                                                <option value="20">20%</option>
                                                <option value="21">21%</option>
                                                <option value="22">22%</option>
                                                <option value="23">23%</option>
                                                <option value="24">24%</option>
                                                <option value="25">25%</option>
                                                <option value="26">26%</option>
                                                <option value="27">27%</option>
                                                <option value="28">28%</option>
                                            </select>
                                            {error_edit?.gst_per && (
                                            <span className="alert alert-danger d-block mt-1 mb-1 pt-1 pb-1">
                                                {error_edit.gst_per && error_edit.gst_per}
                                            </span>
                                            )}
                                        </div>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                                <Form.Label>IGST Charge</Form.Label>
                                                <Form.Control type="number" min={0} className='readonly-input' placeholder="GST Charge.." name="gst_charge" value={formData.gst_charge} readOnly/>
                                            </Form.Group>
                                            
                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                                <Form.Label>Service Charge</Form.Label>
                                                <Form.Control type="number" min={0} placeholder="Service Charge.." onChange={handleChange} name="service_charge" value={formData.service_charge} required/>
                                            </Form.Group>
                                            
                                        </Row>

                                        <Button variant="primary" type="submit" className='d-flex align-items-center justify-content-center'>
                                            <span className='mx-2'>Submit</span>
                                            {loading_update && <span className='spin-sm'><Loader /></span>}
                                        </Button>
                        </Form>
                    </div>
                </div>
            </div>
            {/* Edit Quotation Sidebar End */}
        </div>
    </>
  )
}

export default QuotationList
